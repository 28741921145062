import { t as trans } from 'i18next';

const t = (key, defaultValue, params) => {
  return trans(key, { ...params, defaultValue }).toString();
};

const getUserLanguagePreference = () => {
  const lang = navigator.language.includes('zh') ? 'zh' : 'en';
  return lang;
};

export { t, getUserLanguagePreference };
