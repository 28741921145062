import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  useLocalVideo,
  useRosterState,
  useContentShareState,
  ContentShare,
  useToggleLocalMute
} from 'amazon-chime-sdk-component-library-react';
import { useQuery } from 'utils/hooks/useQuery';
import { Tooltip } from 'antd';
import {
  useMeetingDispatch,
  useMeetingState,
  ChatRoom,
  MeetingStates,
  SettingPopoverButton
} from '@oneboard/meeting';
import {
  TeachingMaterialContainer,
  SyncWhiteboard,
  SyncToolBox,
  VideoListCarouselPanel,
  WorldWallModal,
  BreakoutRoomIntro,
  BreakoutRoomContainer,
  BreakoutRoomResult,
  SyncVideoList,
  RightContainer,
  PrivateMessageModal,
} from 'containers';
import UsersModalMap from 'containers/UsersModal';
import { BreakoutRoomNotification, TeacherSubRoomNotification, BreakoutRoomResultNotification } from 'containers/BreakoutRoomNotification';
import { MainLayout } from 'layouts';
import MainPageHeader from './Header';
import { RaiseHandControl, ScenesImageUploadButton, SyncMainBlock } from 'components';
import { Roles, NOTIFICATION_TYPE, NOTIFICATION_TYPE_KEY } from 'constants/index';
import { useGroupContext } from 'providers/GroupProvider';
import { useVideoListContext } from 'providers/VideoListProvider';
import { useNotification } from 'utils/hooks/useNotification';
import { useViewMode } from 'utils/hooks/useViewMode';
import { useIframeBridge } from 'utils/hooks/useIframeBridge';
import { usePrevious, useRequest } from 'ahooks';
import { complementarySet } from 'utils/array';
import { Box, Modal, FullButton } from '@oneboard/ui-components';
import Icon from '@onedesign/icon';
import PageController from '@netless/page-controller';
import PreviewController from '@netless/preview-controller';
import { useWhiteboard } from '@oneboard/whiteboard';
import { useBreakoutMeeting } from 'providers/BreakoutMeetingProvider';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import {
  StyledSyncMultiple,
  StyleUsersModalLayout,
  IconBox,
  UiChatRoomLayout,
  Sidebar,
  BreakoutRoomNotificationLayout,
  ContentShareContainer,
  StyledBroadcastModal,
  StyledSyncVideoList,
  StyledPrivateMessageButton
} from './SyncMultiple.style';

export const SyncMultiple = () => {
  const { meetingId, breakoutId } = useParams();
  const query = useQuery();
  const meetingState = useMeetingState();
  const { callTeacherGroups, attendeeId: selfAttendeeId, videoList } = meetingState.context;
  const {
    onstageFullModalState,
    toggleStageFullModal,
    usersModalState,
    toggleUsersModal,
    carouselPanelState,
    toggleCarouselPanel,
    worldWallModalState,
    toggleWorldWallModal,
    breakoutRoomSidebarState,
    BreakoutRoomState,
    toggleBreakoutRoomSidebar,
    breakoutRoomStep,
    changeBreakoutRoomStep,
    breakoutRoomEndNotice,
    closeBreakoutRoomEndNotice,
    toggleBreakoutRoomIsLeaving,
    breakoutRoomIntroState,
    breakoutRoomIntroActions,
    setBreakoutRoomResultData,
    breakoutRoomResultState,
    breakoutRoomResultActions,
    resultReviewState,
    resultReviewActions,
    breakoutRoomIsEndState,
    setBreakoutRoomIsEndState,
    broadcastModalState,
    setBroadcastModalActions
  } = useGroupContext();
  const { videoListCarouselStart, videoListCarouselStop, videoList: localVideoList } = useVideoListContext();
  const { breakoutMeetingLeave, removeAllCallTeacherGroups } = useMeetingDispatch();
  const { isBreakoutRoom, stopBreakout, getBreakoutLatest, goToBreakoutMeeting, broadcastMessageToAll } = useBreakoutMeeting();

  const [iframeGroup, setIframeGroup] = useState([]);
  const { openNotification } = useNotification();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const students = attendees.filter(attendee => attendee.role === Roles.Student);
  const isTeacherJoined = attendees.find(attendee => attendee.role === Roles.Teacher);
  const studentsPrevious = usePrevious(students) || [];
  const { service: whiteboardService, addPage } = useWhiteboard();
  const [chatRoomState, setChatRoomState] = useState(false);
  useViewMode();
  useIframeBridge();
  const breakoutRoomStorage = window.localStorage;
  const callTeacherGroupsPrevious = usePrevious(callTeacherGroups);
  const { muted, toggleMute } = useToggleLocalMute();
  const [privateMessageModalState, setPrivateMessageModalState] = useState(false);
  const mainRef = useRef(null);

  const closeTeachingMaterialHandler = material => {
    switch (material.type) {
      case 'iframe':
        setIframeGroup(prev => prev.filter(item => item.sources !== material.sources));
        break;
      default:
        break;
    }
  };

  const toggleUsersModalHandler = () => {
    toggleUsersModal();
    usersModalState ? videoListCarouselStart() : videoListCarouselStop();
  };

  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const isJoined = meetingState.matches(MeetingStates.Joined);

  useEffect(() => {
    if (!isVideoEnabled && query.role !== Roles.Advisor) {
      toggleVideo();
    }
  }, [isJoined]);

  const notificationStudentNumberChangeHandler = () => {
    const complementarySetResult = complementarySet(students, studentsPrevious);
    if (students.length > studentsPrevious.length) {
      complementarySetResult.map(student => openNotification({
        name: student.name,
        type: NOTIFICATION_TYPE.ENTER,
        key: NOTIFICATION_TYPE_KEY.ENTER
      }));
    } else {
      complementarySetResult.map(student => openNotification({
        name: student.name,
        type: NOTIFICATION_TYPE.LEAVE,
        key: NOTIFICATION_TYPE_KEY.LEAVE
      }));
    }
  };

  const [isShowPreviewMenu, setIsShowPreviewMenu] = useState(false);

  const pagePreviewHandler = (state) => {
    setIsShowPreviewMenu(state);
  };

  const toggleChatRoomHandler = () => setChatRoomState(prev => !prev);

  const cancelBreakoutRoomEndNoticeHandler = () => closeBreakoutRoomEndNotice();

  const closeBreakoutRoomEndNoticeHandler = async () => {
    closeBreakoutRoomEndNotice();
    toggleBreakoutRoomIsLeaving();
    breakoutMeetingLeave();
    await stopBreakout(meetingId);

    removeAllCallTeacherGroups();
    breakoutRoomStorage.removeItem('breakoutRoomData');
    breakoutRoomStorage.removeItem('callTeacherData');
    setBreakoutRoomIsEndState(true);
    resultReviewActions.setTrue();
  };

  const [groupInvitationModal, setGroupInvitationModal] = useState(false);
  const toggleGroupInvitationModal = () => setGroupInvitationModal(prev => !prev);

  const getResultData = async () => {
    const data = await getBreakoutLatest(meetingId);
    if (!data) return;
    setBreakoutRoomResultData(data);
    breakoutRoomResultActions.setTrue();
    resultReviewActions.setTrue();
  };

  useEffect(() => {
    if (breakoutRoomIsEndState) {
      getResultData();
    }
  }, [breakoutRoomIsEndState]);

  useEffect(() => {
    notificationStudentNumberChangeHandler();
  }, [students.length]);

  const [breakoutRoomInfo, setBreakoutRoomInfo] = useState(null);
  const [breakoutRoomGrouping, setBreakoutRoomGrouping] = useState(false);

  const getLatestData = async () => {
    const data = await getBreakoutLatest(meetingId);
    if (!data) return;
    setBreakoutRoomResultData(data);
    const groupInfo = data.groups.filter(group => group.roomId === breakoutId);
    setBreakoutRoomInfo(groupInfo[0]);
    setBreakoutRoomGrouping(data.status === 'Start' ? true : false);
  };

  useEffect(() => {
    if (!breakoutRoomGrouping) return;
    changeBreakoutRoomStep(breakoutRoomGrouping ? BreakoutRoomState.grouping : BreakoutRoomState.setting);
  }, [breakoutRoomGrouping]);

  useEffect(() => {
    getLatestData();
  }, []);

  useEffect(() => {
    if (!callTeacherGroupsPrevious) return;
    if (callTeacherGroupsPrevious.length === 0) setGroupInvitationModal(true);
  }, [callTeacherGroups]);

  const goBreakoutInviteHandler = () => {
    goToBreakoutMeeting({ breakoutId: callTeacherGroups[0].breakoutRoomId });
  };

  const handleSceneUpload = () => {
    pagePreviewHandler(true);
    setTimeout(() => {
      const element = document.querySelector('nav.bm-item-list > .bm-item');
      element.scrollTop = element.scrollHeight;
    }, 100);
  };

  const { sharingAttendeeId } = useContentShareState();
  const contentSharingAttendeeId = useMemo(() => sharingAttendeeId?.split('#')[0] || '', [sharingAttendeeId]);
  const isShowContentShare = useMemo(() =>
    contentSharingAttendeeId && contentSharingAttendeeId !== selfAttendeeId,
    [selfAttendeeId, contentSharingAttendeeId]);

  const [broadcastValue, setBroadcastValue] = useState('');

  const broadcastValueChangeHandler = (e) => {
    const textAreaValue = e.target.value.trim();
    if (textAreaValue.length > 280) return;

    setBroadcastValue(textAreaValue);
  };

  const broadcastModalCloseHandler = () => {
    setBroadcastModalActions.setFalse();
  };

  const { loading: broadcastModalLoading, run: broadcastMessageRun } = useRequest(() => broadcastMessageToAll({ courseId: meetingId, message: broadcastValue }), {
    manual: true
  });

  const broadcastModalSubmitHandler = async () => {
    await broadcastMessageRun();
    setBroadcastValue('');
    setBroadcastModalActions.setFalse();
  };

  // 私訊彈窗
  const onPrivateMessageClose = () => setPrivateMessageModalState(false);

  /**
   * 老師不在時用自己的輪播列表
   */
  const nextVideoStudentList = useMemo(() => {
    return isTeacherJoined ? videoList : localVideoList;
  }, [isTeacherJoined, videoList, localVideoList]);

  useEffect(() => {
    if (!muted) {
      toggleMute();
    }
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%">
      {!isBreakoutRoom && breakoutRoomStep === BreakoutRoomState.grouping && <BreakoutRoomNotificationLayout>
        <BreakoutRoomNotification meetingId={meetingId} />
      </BreakoutRoomNotificationLayout>}
      {isBreakoutRoom && <BreakoutRoomNotificationLayout>
        <TeacherSubRoomNotification title={breakoutRoomInfo?.groupName} />
      </BreakoutRoomNotificationLayout>}
      {breakoutRoomStep === BreakoutRoomState.setting && resultReviewState && whiteboardService && <BreakoutRoomNotificationLayout>
        <BreakoutRoomResultNotification service={whiteboardService} />
      </BreakoutRoomNotificationLayout>}

      <StyledSyncMultiple>
        <MainLayout
          right={
            <RightContainer>
              <MainPageHeader />
            </RightContainer>
          }
          mode='sync'
        >
          <SyncMainBlock ref={mainRef}>
            {nextVideoStudentList.length > 0 && <StyledSyncVideoList>
              <SyncVideoList videoList={nextVideoStudentList} />
            </StyledSyncVideoList>}

            <SyncWhiteboard toolBox={<SyncToolBox meetingId={meetingId} />}>
              <Box
                position="absolute"
                zIndex={50}
                bottom="40px"
                right={2}
                mb={2}
              >
                {
                  isTeacherJoined && (
                    <Tooltip placement="top" title='私訊老師'>
                      <StyledPrivateMessageButton onClick={() => setPrivateMessageModalState(true)}>
                        <Icon name="WalkieTalkie" size='xl' color="#EC7963" />
                      </StyledPrivateMessageButton>
                    </Tooltip>
                  )
                }
              </Box>
              <Box
                display="flex"
                position="absolute"
                zIndex={5}
                bottom={2}
                right={2}
              >
                {!isBreakoutRoom && <Box mr={2}>
                  <RaiseHandControl sync />
                </Box>}
                {!isBreakoutRoom && <>
                  <Box mr={2}>
                    <IconBox onClick={toggleUsersModalHandler}>
                      <Icon name="UsersSolid" />
                    </IconBox>
                  </Box>
                  <Box mr={2}>
                    <IconBox onClick={() => pagePreviewHandler(true)}>
                      <Icon name="ThSolid" size="xs" />
                    </IconBox>
                  </Box>
                  <Box mr={2}>
                    <IconBox>
                      <SettingPopoverButton
                        className="settingPopoverButton"
                        placement="leftTop"
                        disabledPopover
                      />
                    </IconBox>
                  </Box>
                  <Box mr={2}>
                    <ScenesImageUploadButton service={whiteboardService} onUploaded={handleSceneUpload}>
                      <IconBox>
                        <Icon name="ImageSolid" size="xs" />
                      </IconBox>
                    </ScenesImageUploadButton>
                  </Box>
                  <Box>
                    <IconBox onClick={addPage}>
                      <Icon name="FileAddSolid" size="xs" />
                    </IconBox>
                  </Box>
                  <Box ml={2}>
                    <PageController room={whiteboardService} />
                  </Box>
                </>}
              </Box>

              <PreviewController
                room={whiteboardService}
                isVisible={isShowPreviewMenu}
                handlePreviewState={pagePreviewHandler}
              />
            </ SyncWhiteboard>

            {
              isShowContentShare && (
                <ContentShareContainer>
                  <ContentShare />
                </ContentShareContainer>
              )
            }

            <TeachingMaterialContainer iframeGroup={iframeGroup} onClose={closeTeachingMaterialHandler} />

            {worldWallModalState && <WorldWallModal onClose={toggleWorldWallModal} />}
            <UiChatRoomLayout active={chatRoomState}>
              <ChatRoom onClose={toggleChatRoomHandler} />
            </UiChatRoomLayout>
            {carouselPanelState && <VideoListCarouselPanel onClose={toggleCarouselPanel} />}
            {onstageFullModalState && <Modal onClose={toggleStageFullModal} header='警告通知' >人數已滿無法再邀請人上台</ Modal>}
            <StyleUsersModalLayout active={usersModalState}>
              <UsersModalMap.AdvisorUsersModal onClose={toggleUsersModalHandler} />
            </StyleUsersModalLayout>

            {breakoutRoomStep === BreakoutRoomState.setting && breakoutRoomResultState && <BreakoutRoomResult service={whiteboardService} />}

          </SyncMainBlock>

        </MainLayout>
        <Sidebar isSidebar={breakoutRoomIntroState}>
          <BreakoutRoomIntro onClose={breakoutRoomIntroActions.setFalse} />
        </Sidebar>
        <Sidebar isSidebar={breakoutRoomSidebarState}>
          <BreakoutRoomContainer onClose={toggleBreakoutRoomSidebar} isGrouping={breakoutRoomGrouping} />
        </Sidebar>

        {breakoutRoomEndNotice && <Modal onClose={cancelBreakoutRoomEndNoticeHandler} header='結束分組'>
          <Box>
            結束分組討論後，分組成員將有30秒緩衝時間，將會在緩衝時間後回到主教室，結束分組後將會進入分組檢討，是否確認結束此次分組討論？
          </Box>
          <Box display="flex" width="100%" pt={8}>
            <Box width="100%" mr={2}>
              <FullButton.Secondly onClick={cancelBreakoutRoomEndNoticeHandler}>取消</FullButton.Secondly>
            </Box>
            <Box width="100%" ml={2}>
              <FullButton onClick={closeBreakoutRoomEndNoticeHandler}>結束分組討論</FullButton>
            </Box>
          </Box>
        </Modal>}
        {groupInvitationModal && callTeacherGroups.length === 1 && <Modal width="600px" onClose={toggleGroupInvitationModal} header='收到加入討論邀請'>
          <Box>
            已收到 {callTeacherGroups[0].groupName} 分組討論室的加入邀請，是否立即加入討論？
          </Box>
          <Box display="flex" width="100%" pt={8}>
            <Box width="100%" mr={2}>
              <FullButton.Secondly onClick={toggleGroupInvitationModal}>稍後加入</FullButton.Secondly>
            </Box>
            <Box width="100%" ml={2}>
              <FullButton onClick={goBreakoutInviteHandler}>立即加入</FullButton>
            </Box>
          </Box>
        </Modal>}

        {broadcastModalState && <Modal width="600px" header='廣播訊息'>
          <StyledBroadcastModal>
            <div className="content">
              <textarea placeholder="在這裡輸入廣播訊息，傳送給所有成員。" maxLength={280} onChange={broadcastValueChangeHandler}></textarea>
              <div className="tip">{broadcastValue.length}/280</div>
            </div>
          </StyledBroadcastModal>
          <Box display="flex" width="100%" pt={8}>
            <Box width="100%" mr={2}>
              <FullButton.Secondly onClick={broadcastModalCloseHandler} disabled={broadcastModalLoading ? true : false}>取消</FullButton.Secondly>
            </Box>
            <Box width="100%" ml={2}>
              <FullButton onClick={broadcastModalSubmitHandler} disabled={broadcastValue.length === 0 ? true : false}>
                {broadcastModalLoading ? <LoadingOutlined /> : '發送訊息'}
              </FullButton>
            </Box>
          </Box>
        </Modal>}

        {(isTeacherJoined && privateMessageModalState) && <Modal width="600px" header="提示訊息" onClose={onPrivateMessageClose}>
          <PrivateMessageModal
            onClose={onPrivateMessageClose}
            targetRole={Roles.Teacher}
            placeholder="在這裡輸入提示訊息，傳送給授課老師。"
          />
        </Modal>}
      </StyledSyncMultiple>
    </Box>

  );
};
