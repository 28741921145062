import { useMemo } from 'react';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import { useMeetingDispatch, useMeetingState, MeetingStates, MeetingRoles } from '@oneboard/meeting';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import Icon from '@onedesign/icon';
import { Roles } from 'constants/index';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import { t } from 'utils/i18n';

export const RewardButton = () => {
  const { roster } = useRosterState();
  const { isFooterOpen } = useFooterStatus();
  const attendees = Object.values(roster);
  const { sendReward, sendRewardFromAdvisor, updateReward, updateRewardFromAdvisor } = useMeetingDispatch();
  const meetingState = useMeetingState();
  const { role } = meetingState.context;
  const student = useMemo(() => attendees.find((attendee) => attendee.role === Roles.Student), [attendees]);
  const isTeacherRewardActive = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Teacher}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`,
  });
  const isAdvisorRewardActive = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Advisor}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`,
  });

  const handleReward = () => {
    if (!student) return;
    if (isTeacherRewardActive || isAdvisorRewardActive) return;
    const { chimeAttendeeId: attendeeId, userId, name: studentName } = student;
    if (role === Roles.Advisor) {
      updateRewardFromAdvisor({ userId, studentName });
      sendRewardFromAdvisor({ attendeeId, userId });
    } else {
      updateReward({ userId, studentName });
      sendReward({ attendeeId, userId, studentName });
    }
  };
  return (
    <ToolButton
      icon={<Icon name='TrophySolid' size='md' color='#FEC902' />}
      onClick={handleReward}
    >
      {isFooterOpen && t('components.newOneBoard.footer.rewardButton.reward', '獎勵')}
    </ToolButton>
  );
};
