import React, { useRef } from 'react';
import {
  StyledWaitingAnimation,
  Animation,
  AnimationWrap,
  TextContainer,
  StyledHeader,
  StyledText,
} from './MeetingFailurePage.style';
import Time from './Time.gif';
import { SendButton } from 'components/NewOneBoard/common/MessageButton';
import { t } from 'utils/i18n';

export const MeetingFailurePage = () => {
  const ref = useRef();
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <StyledWaitingAnimation>
      <AnimationWrap ref={ref}>
        <Animation ref={ref}>
          <img width={'200px'} src={Time} alt='Time' />
        </Animation>
        <TextContainer>
          <StyledHeader>{t('components.meetingFailurePage.title', '教室已關閉')}</StyledHeader>
          <StyledText>
            {t(
              'components.meetingFailurePage.message',
              '您已超過30分鐘沒有動作，請點擊「重新整理」重新進入教室。'
            )}
          </StyledText>
        </TextContainer>
        <SendButton onClick={handleRefresh}>
          {t('components.meetingFailurePage.refreshButton', '重新整理')}
        </SendButton>
      </AnimationWrap>
    </StyledWaitingAnimation>
  );
};
