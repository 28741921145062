import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from '@oneboard/ui-components';
import { useMeetingDispatch, useMeetingState } from '@oneboard/meeting';
import Icon from '@onedesign/icon';
import { getMultiUserRecord } from 'services/userRecord';
import { formatSeconds } from 'utils/time';
import { t } from 'utils/i18n';
import ToolModal from 'components/NewOneBoard/common/ToolModal';
import { SendButton } from 'components/NewOneBoard/common/MessageButton';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 30, color: '#ec7963' }} spin />;

const StyledModalHead = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 12px;
  color: #637381;
  > .title {
    flex: 1;
  }
`;

const StyledModalBody = styled.div`
  max-height: 188px;
  margin-right: ${({ isScrollable }) => (isScrollable ? '-18px' : 0)};
  overflow-y: scroll;
`;

const StyledUserCell = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  font-weight: 500;

  .icon {
    &.performance {
      width: 50px;
      height: 50px;
      padding: 4px;
      background-color: rgba(59, 36, 109, 1);
      border-radius: 50%;
    }
    &.clock {
      width: 20px;
      height: 20px;
    }
    > img {
      width: 100%;
    }
  }
`;

const ButtonContainer = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const ModalText = styled.p`
  color: #637381;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  padding-left: 8px;
  margin: 0;
`;

const TurnOffButton = styled(SendButton)`
  width: 150px;
  background: #fff;
  color: #ec7963;
  border: 1px solid #ec7963;

  &:hover {
    background: #ec7963;
    color: #fff;
    border: unset;
  }
`;

const StudentsContainer = styled.div``;

const UserCell = ({ user }) => {
  return (
    <StyledUserCell>
      <Box className='iconGroup' flex={1} display='flex'>
        <Box display='flex' alignItems='center' flex='2'>
          <Box className='icon performance' mr={2} display='flex' alignItems='center'>
            <img src={`/images/performance-icon-${user.iconIndex}.svg`} alt='' />
          </Box>
          {user.name}
        </Box>
        <Box display='flex' alignItems='center' ml={1} flex='2'>
          <Box className='icon clock' mr={2} display='flex' alignItems='center'>
            <img src='/images/clock.svg' alt='' />
          </Box>
          {formatSeconds(user.speakerSecond)}
        </Box>
        <Box display='flex' alignItems='center' ml={1} flex='1'>
          <Box className='icon' mr={2} display='flex' alignItems='center'>
            <Icon name='TrophySolid' size='sm' color='#F9C74F' />
          </Box>
          {user.trophy} {t('components.newOneBoard.footer.performanceModal.count', '個')}
        </Box>
      </Box>
    </StyledUserCell>
  );
};

UserCell.propTypes = {
  user: PropTypes.object,
};

export const PerformanceModal = ({ modalOpen, onClose, students }) => {
  const { openClassroomPerformance, closeClassroomPerformance } = useMeetingDispatch();
  const state = useMeetingState();
  const { context } = state;
  const { roomId } = context;
  const [studentsPerformance, setStudentsPerformance] = useState([]);
  const [openConfirmClose, setOpenConfirmClose] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleConfirmCancel = () => setOpenConfirmClose(false);

  const handleConfirmClose = () => {
    handleConfirmCancel();
    onClose();
    closeClassroomPerformance();
  };
  const init = async () => {
    setIsLoading(true);
    const multiRecord = await getMultiUserRecord({
      courseId: roomId,
    });
    const studentsRecord = students.map((student, index) => {
      const record = multiRecord.find((item) => item.userId === student.userId);
      return {
        iconIndex: index % 6,
        ...student,
        ...record.summary,
      };
    });

    const reducedStudentsRecord = studentsRecord.map((student) => ({
      iconIndex: student.iconIndex,
      name: student.name,
      role: student.role,
      userId: student.userId,
      trophy: student.trophy,
      speakerSecond: student.speakerSecond,
    }));

    setStudentsPerformance(studentsRecord);
    openClassroomPerformance({ classroomPerformance: reducedStudentsRecord });
    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <ToolModal
        active={modalOpen}
        onClose={handleConfirmClose}
        title={t('components.newOneBoard.footer.performanceModal.classroomPerformance', '課堂表現')}
        width='600px'
        height='400px'
        footerHeight='120px'
        zIndex={openConfirmClose && 5}
        justifyContent={isLoading ? 'center' : 'flex-start'}
        footerContent={
          <ButtonContainer>
            <TurnOffButton onClick={handleConfirmClose}>
              {t('components.newOneBoard.footer.performanceModal.turnOffDisplay', '關閉顯示')}
            </TurnOffButton>
            <ModalText>
              {t('components.newOneBoard.footer.performanceModal.turnOffMessage', '關閉顯示後，會同步關閉學生的課堂表現')}
            </ModalText>
          </ButtonContainer>
        }
      >
        {isLoading ? (
          <Spin indicator={antIcon} />
        ) : (
          <StudentsContainer>
            <StyledModalHead>
              <Box flex='2'>{t('containers.classroomPerformanceModal.studentName', '學生姓名')}</Box>
              <Box flex='2'>{t('containers.classroomPerformanceModal.speakingTime', '發言時間')}</Box>
              <Box flex='1'>{t('containers.classroomPerformanceModal.noTrophies', '獎盃數')}</Box>
            </StyledModalHead>
            <StyledModalBody>
              {studentsPerformance.length > 0 &&
                studentsPerformance.map((item) => <UserCell key={item.userId} user={item} />)}
            </StyledModalBody>
          </StudentsContainer>
        )}
      </ToolModal>
    </>
  );
};

PerformanceModal.propTypes = {
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  students: PropTypes.array,
};
