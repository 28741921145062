import React, { createContext, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { getCourse } from 'api/oneClub';
import { getInternalCourse } from 'api/course';
import { useMeetingState } from '@oneboard/meeting';
import { API_STATUS, ClassType, Roles } from 'constants/index';

const CourseInfoContext = createContext();

export const CourseInfoProvider = ({ children }) => {
  const meetingState = useMeetingState();
  const { role, courseType, roomId } = meetingState.context;
  const [courseInfo, setCourseInfo] = useState();

  const getCourseInfo = async () => {
    if (!roomId) return;
    // 取一般課程資料

    const courseInfo = await getCourse(roomId);
    const { status, data } = courseInfo;
    if (status === API_STATUS.SUCCESS && data) {
      const _data = {
        ...data,
        interactiveLearnings: data.interactiveLearnings.map((interactiveLearning) => {
          return {
            ...interactiveLearning,
            seriesCode: interactiveLearning.series[0].code
          };
        })
      };
      setCourseInfo(_data);
      return;
    }

    // 取課外學習資料
    const publicCourseInfo = await getInternalCourse(roomId);
    const { status: publicCourseStatus, data: publicCourseData } = publicCourseInfo;
    if (publicCourseStatus === API_STATUS.SUCCESS && publicCourseData) {
      if ('id' in publicCourseData) {
        setCourseInfo(publicCourseData);
      }
    }
  };

  useEffect(() => {
    if (role === Roles.Observer) return;
    if (courseType === ClassType.SyncMultiple || courseType === ClassType.SyncSingle || courseType === ClassType.SyncStreaming) {
      getCourseInfo();
    }
  }, [role, courseType]);

  return <CourseInfoContext.Provider value={{ courseInfo }}>{children}</CourseInfoContext.Provider>;
};

export const useCourseInfo = () => {
  const context = useContext(CourseInfoContext);
  if (!context) throw new Error('useCourseInfo must be used within a CourseInfoProvider');

  return context;
};

CourseInfoProvider.propTypes = {
  children: PropTypes.node
};
