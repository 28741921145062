import React, { useState, useMemo, useContext, createContext } from 'react';
import Icon from '@onedesign/icon';
import PropTypes from 'prop-types';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import { useCourseInfo } from 'providers/CourseInfoProvider';
import { BookList } from 'containers';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import ToolModal from 'components/NewOneBoard/common/ToolModal';
import { ResourceModalProvider } from 'providers/ResourceModalProvider';
import { ResourceInteractiveLearningModalProvider } from 'providers/ResourceInteractiveLearningModalProvider';
import { t } from 'utils/i18n';

const MaterialButtonContext = createContext();

function MaterialButtonProvider({ children }) {
  const [open, setOpen] = useState(false);

  const showModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const memoValue = useMemo(() => {
    return {
      open,
      showModal,
      closeModal
    };
  }, [open, showModal, closeModal]);

  return (
    <MaterialButtonContext.Provider value={memoValue}>
      {children}
    </MaterialButtonContext.Provider>
  );
}

MaterialButtonProvider.propTypes = {
  children: PropTypes.node,
};

export const useMaterialButton = () => {
  const context = useContext(MaterialButtonContext);

  if (!context) throw new Error('useMaterialButton must be used within a MaterialButtonProvider');

  return context;
};

const MaterialButtonToggle = () => {
  const { isFooterOpen } = useFooterStatus();
  const { showModal } = useMaterialButton();

  return (
    <ToolButton
      icon={<Icon name='BooksSolid' size='md' color='#4246A8' />}
      onClick={showModal}
    >
      {isFooterOpen && t('components.newOneBoard.footer.materialButton.materials', '教材')}
    </ToolButton>
  );
};

const MaterialButtonModal = () => {
  const { courseInfo } = useCourseInfo();
  const { open, closeModal } = useMaterialButton();

  return (
    <ToolModal
      active={open}
      onClose={closeModal}
      title={t('components.newOneBoard.footer.materialButton.materials', '教材')}
      height='450px'
      justifyContent='flex-start'>
      <BookList courseInfo={courseInfo} width='auto' pd='0' />
    </ToolModal>
  );
};

export const MaterialButton = () => (
  <MaterialButtonProvider>
    <ResourceInteractiveLearningModalProvider>
      <ResourceModalProvider>
        <MaterialButtonToggle />
        <MaterialButtonModal />
      </ResourceModalProvider>
    </ResourceInteractiveLearningModalProvider>
  </MaterialButtonProvider>
);
