import { useWhiteboard } from '@oneboard/whiteboard';
import { IframeBridge } from '@oneboard/iframe-bridge';
import { useGroupContext } from 'providers/GroupProvider';
import { useResourceModal } from 'providers/ResourceModalProvider';
import { useMaterialButton } from 'components/NewOneBoard/Footer/component/MaterialButton/MaterialButton';

export const useBook = () => {
  const { service: whiteboardService } = useWhiteboard();
  const { addSceneQueue } = useGroupContext();
  const { closeModal: closeMaterialButtonModal } = useMaterialButton();
  const { setCurrentBook } = useResourceModal();

  const bookIdChangeHandler = async ({ bookInfo }) => {
    const { pageInfos, bookId, display_name: label, resourceName } = bookInfo;
    if (pageInfos && pageInfos.length > 0) {
      const width = pageInfos[0].width;
      const height = pageInfos[0].height;
      const defaultPageIndex = 0;
      const DIR = `/book_iframe_${bookId}`;
      whiteboardService.putScenes(
        DIR,
        pageInfos.map((pageInfo) => ({ name: `${pageInfo.pageIndex + 1}` }))
      );
      whiteboardService.setScenePath(`/book_iframe_${bookId}/1`);
      setCurrentBook(bookId);
      const url = `${process.env.REACT_APP_ONEBOARD_READER_DOMAIN}/${bookId}/${defaultPageIndex}`;

      let bridge = await whiteboardService.getInvisiblePlugin(IframeBridge.kind);
      if (!bridge) {
        bridge = await IframeBridge.insert({
          room: whiteboardService,
          url, // iframe 的地址
          width,
          height,
          displaySceneDir: DIR,
          useSelector: true,
        });
      } else {
        bridge.setAttributes({
          url,
          width,
          height,
          displaySceneDir: DIR,
        });
      }
      bridge.scaleIframeToFit();
      whiteboardService.dispatchMagixEvent('changeBookId');
      addSceneQueue({ bookId: `book_iframe_${bookId}`, label, resourceName, width, height, name: '1' });
      closeMaterialButtonModal();
    }
  };

  return { bookIdChangeHandler };
};