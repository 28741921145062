import {
  createContext,
  useContext,
  useState,
  useMemo
} from 'react';
import PropTypes from 'prop-types';

const InteractiveLearningContext = createContext(null);

const InteractiveLearningProvider = ({ children }) => {
  const [interactiveLearningMap, setInteractiveLearningMap] = useState({});

  const memoValue = useMemo(() => ({
    interactiveLearningMap,
    setInteractiveLearningMap,
  }), [interactiveLearningMap]);

  return (
    <InteractiveLearningContext.Provider value={memoValue}>
      {children}
    </InteractiveLearningContext.Provider>
  );
};

InteractiveLearningProvider.propTypes = {
  children: PropTypes.node,
};

const useInteractiveLearning = () => {
  const context = useContext(InteractiveLearningContext);
  if (!context) throw new Error('useInteractiveLearning must be used within a InteractiveLearningProvider');

  return context;
};

export { InteractiveLearningProvider, useInteractiveLearning };