import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { Box } from '@oneboard/ui-components';
import Icon from '@onedesign/icon';
import { StyledResourceBox, StyledResource, StyledResourceItem } from '../BookList.style';
import { useResourceInteractiveLearningModal } from 'providers/ResourceInteractiveLearningModalProvider';
import { useInteractiveLearning } from 'providers/InteractiveLearningProvider';
import { pipe, prop, groupBy, map, omit, indexBy } from 'ramda';
import { useWhiteboard } from '@oneboard/whiteboard';
import { useGroupContext } from 'providers/GroupProvider';
import { useCourseInfo } from 'providers/CourseInfoProvider';
import { IframeBridge } from '@oneboard/iframe-bridge';
import { getInteractiveLearningById } from 'api/oneClub';
import { notification } from 'antd';
import { v4 as uuid } from 'uuid';
import { useMaterialButton } from 'components/NewOneBoard/Footer/component/MaterialButton/MaterialButton';

const InteractiveLearningsBooks = () => {
  const { currentBookId, setCurrentBook, toggleResourceInteractiveLearningModalSwitch, setSeriesData } = useResourceInteractiveLearningModal();
  const { setInteractiveLearningMap } = useInteractiveLearning();
  const { service: whiteboardService } = useWhiteboard();
  const { courseInfo } = useCourseInfo();
  const { addSceneQueue } = useGroupContext();
  const { closeModal: closeMaterialButtonModal } = useMaterialButton();
  const [interactiveLearningsMap, setInteractiveLearningsMap] = useState();
  const [seriesMap, setSeriesMap] = useState();

  const handleBookClick = async (id, title) => {
    const { data, error } = await getInteractiveLearningById(id);
    if (error) {
      notification.error({
        className: 'customNotification',
        message: error?.message ?? '取得資料失敗',
        key: uuid()
      });
      return;
    }
    const gameCode = data?.interactiveLearning?.gameCode;
    if (!gameCode) {
      notification.error({
        className: 'customNotification',
        message: '沒有 gameCode 資料',
        key: uuid()
      });
      return;
    }
    const width = 1024;
    const height = 768;
    const DIR = `/interactive_learnings_${id}`;
    whiteboardService.putScenes(
      DIR,
      [{ name: '1' }]
    );
    whiteboardService.setScenePath(`${DIR}/1`);
    setCurrentBook(id);
    const url = `${process.env.REACT_APP_INTERACTIVE_LEARNING_DOMAIN}?gameCode=${gameCode}`;
    setInteractiveLearningMap(prev => {
      return {
        ...prev,
        [id]: { gameCode }
      };
    });

    let bridge = await whiteboardService.getInvisiblePlugin(IframeBridge.kind);
    if (!bridge) {
      bridge = await IframeBridge.insert({
        room: whiteboardService,
        url, // iframe 的地址
        width,
        height,
        displaySceneDir: DIR,
        useSelector: true,
      });
    } else {
      bridge.setAttributes({
        url,
        width,
        height,
        displaySceneDir: DIR,
      });
    }
    bridge.scaleIframeToFit();
    whiteboardService.dispatchMagixEvent('changeBookId');
    addSceneQueue({ bookId: `interactive_learnings_${id}`, label: title, resourceName: title, width, height, name: '1' });
    closeMaterialButtonModal();
  };

  const moreClickHandler = (seriesData) => {
    setSeriesData(seriesData);
    toggleResourceInteractiveLearningModalSwitch();
  };

  useEffect(() => {
    const init = () => {
      const { series, interactiveLearnings } = courseInfo;
      if (!interactiveLearnings || interactiveLearnings.length === 0) return;
      const _seriesMap = indexBy(prop('code'), series);
      const _interactiveLearningsMap = pipe(groupBy(prop('seriesCode')), map(map(omit(['seriesCode']))))(interactiveLearnings);
      setInteractiveLearningsMap(_interactiveLearningsMap);
      setSeriesMap(_seriesMap);
    };

    init();
  }, []);

  return (
    <StyledResource>
      {interactiveLearningsMap &&
        seriesMap &&
        Object.keys(interactiveLearningsMap).map((seriesCode) => {
          return (
            <StyledResourceBox key={seriesCode}>
              <div className='head'>
                <div className='dot'></div>
                <div className='seriesTitle'>{seriesMap[seriesCode]?.name}</div>
                <Button
                  size='small'
                  type='text'
                  onClick={() => moreClickHandler(seriesMap[seriesCode])}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box mr='8px'>查看全部</Box>
                  <Icon name='ChevronRightOutline' size='xs' />
                </Button>
              </div>
              <div className='content'>
                <div className='dottedLine'></div>
                <div className='list'>
                  {interactiveLearningsMap[seriesCode]?.map((interactiveLearning) => {
                    return (
                      <StyledResourceItem
                        key={interactiveLearning.id}
                        onClick={() => handleBookClick(interactiveLearning.id, interactiveLearning.resourceTitle)}
                        active={currentBookId === interactiveLearning.id}
                      >
                        {interactiveLearning.resourceTitle}
                      </StyledResourceItem>
                    );
                  })}
                </div>
              </div>
            </StyledResourceBox>
          );
        })}
    </StyledResource>
  );
};

export default InteractiveLearningsBooks;
