import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'utils/hooks/useQuery';
import { Modal } from '@oneboard/ui-components';
import { useResourceModal } from 'providers/ResourceModalProvider';
import { getResource, fetchTagsByRecursion } from 'api/oneClub';
import { useWhiteboard } from '@oneboard/whiteboard';
import { IframeBridge } from '@oneboard/iframe-bridge';
import { useGroupContext } from 'providers/GroupProvider';
import { StyledResourceModal, StyledResourceBox, StyledModalContent, StyledResourceItem } from './ResourceModal.style';
import { useMaterialButton } from 'components/NewOneBoard/Footer/component/MaterialButton/MaterialButton';
import { t } from 'utils/i18n';

const getResourceData = async (seriesData) => {
  const tags = await fetchTagsByRecursion({
    fetchFunc: getResource,
    params: {
      seriesCodes: [seriesData.code]
    },
  });
  return tags;
};

const getBookJsonById = async (bookId) => {
  return fetch(`${process.env.REACT_APP_BOOKS_CDN_DOMAIN}/${bookId}/book.json`).then(res => res.json());
};

const fetchBook = async bookId => await getBookJsonById(bookId);
export const ResourceModalContent = ({ className }) => {
  const { service: whiteboardService } = useWhiteboard();
  const { closeModal: closeMaterialButtonModal } = useMaterialButton();
  const query = useQuery();
  const bookIds = query?.bookIds?.filter(id => id !== '') || null;
  const { addSceneQueue } = useGroupContext();
  const { toggleResourceModalSwitch, seriesData, currentBookId, setCurrentBook } = useResourceModal();
  const [resourceData, setResourceData] = useState();

  const closeModal = () => toggleResourceModalSwitch();

  const bookIdChangeHandler = async (bookInfo, resourceName) => {
    const { pageInfos, bookId, display_name: label } = bookInfo;
    if (pageInfos && pageInfos.length > 0) {
      const width = pageInfos[0].width;
      const height = pageInfos[0].height;
      const defaultPageIndex = 0;
      const DIR = `/book_iframe_${bookId}`;
      whiteboardService.putScenes(DIR, pageInfos.map(pageInfo => ({ name: `${pageInfo.pageIndex + 1}` })));
      whiteboardService.setScenePath(`/book_iframe_${bookId}/1`);
      setCurrentBook(bookId);
      const url = `${process.env.REACT_APP_ONEBOARD_READER_DOMAIN}/${bookId}/${defaultPageIndex}`;

      let bridge = await whiteboardService.getInvisiblePlugin(IframeBridge.kind);
      if (!bridge) {
        bridge = await IframeBridge.insert({
          room: whiteboardService,
          url, // iframe 的地址
          width,
          height,
          displaySceneDir: DIR,
          useSelector: true,
        });
      } else {
        bridge.setAttributes({
          url,
          width,
          height,
          displaySceneDir: DIR,
        });
      }
      bridge.scaleIframeToFit();
      whiteboardService.dispatchMagixEvent('changeBookId');
      addSceneQueue({ bookId: `book_iframe_${bookId}`, label, resourceName, width, height });
      closeModal();
      closeMaterialButtonModal();
    }
  };

  const clickHandler = (bookId, resourceName) => {
    fetchBook(bookId).then(book => {
      bookIdChangeHandler(book, resourceName);
    }).catch(error => console.log(error.message));
  };

  useEffect(() => {
    const init = async () => {
      const _resourceData = await getResourceData(seriesData).then(data => data.filter(resource => !resource.isDeleted));
      setResourceData(_resourceData);
    };

    init();
  }, []);

  return (
    <StyledResourceModal className={className} data-testid="ResourceModal">
      <Modal
        width="600px"
        header={t('components.resourceModal.courseMaterial', '課程教材')}
        type="white"
        onClose={closeModal} >
        <StyledModalContent>
          <StyledResourceBox>
            <div className="head">
              <div className="dot"></div>
              <div className="seriesTitle">{seriesData?.name}</div>
            </div>
            <div className="content">
              <div className="dottedLine"></div>
              <div className="list">
                {resourceData && resourceData.map(resource => (
                  <StyledResourceItem
                    key={resource.id}
                    className="resource"
                    active={currentBookId === resource.bookId}
                    onClick={() => clickHandler(resource.bookId, resource.name)}
                  >
                    <span>{resource.name}</span>
                    {bookIds.includes(resource.bookId) && (
                      <div className="label">
                        {t('components.resourceModal.courseMaterial', '課程教材')}
                      </div>
                    )}
                  </StyledResourceItem>
                ))}
              </div>
            </div>
          </StyledResourceBox>
        </StyledModalContent>
      </Modal>
    </StyledResourceModal>
  );
};

ResourceModalContent.propTypes = {
  className: PropTypes.string,
};

export const ResourceModal = () => {
  const { resourceModalSwitch } = useResourceModal();

  if (!resourceModalSwitch) return null;

  return (
    <ResourceModalContent />
  );
};