import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { useQuery } from 'utils/hooks/useQuery';
import { PRODUCT_TYPE } from 'constants/index';
import { StyledBookList } from './BookList.style';
import { t } from 'utils/i18n';
import Textbooks from './components/Textbooks';
import ExampleBooks from './components/ExampleBooks';
import EduResources from './components/EduResources';
import { ResourceModal, ResourceInteractiveLearningModal } from 'components';
import InteractiveLearningsBooks from './components/InteractiveLearningsBooks';

const { TabPane } = Tabs;

export const BookList = ({ courseInfo, width = '332px', pd = '16px' }) => {
  const query = useQuery();
  const isOnelink = query.productType === PRODUCT_TYPE.onelink;
  const isMMSTeacher = query.productType === PRODUCT_TYPE.mmsTeacher;
  const organizationId = query.organizationId || null;

  return (
    <>
      <StyledBookList data-testid='BookList' width={width} padding={pd}>
        <Tabs>
          <TabPane tab={t('containers.bookList.classMaterial', '上課教材')} key='0'>
            <Textbooks />
          </TabPane>

          {(isOnelink || (isMMSTeacher && organizationId)) && (
            <TabPane tab={t('containers.bookList.eduResources', '教學資源')} key='1'>
              <EduResources />
            </TabPane>
          )}

          <TabPane tab={t('containers.bookList.textbookExample', '教材範例')} key='2'>
            <ExampleBooks />
          </TabPane>

          {courseInfo && <TabPane tab={'互動教材'} key='3'>
            <InteractiveLearningsBooks />
          </TabPane>}
        </Tabs>
      </StyledBookList>

      {/* 更多叢書彈窗 */}
      <ResourceModal />

      {/* 更多叢書互動教材彈窗 */}
      <ResourceInteractiveLearningModal />
    </>
  );
};

BookList.propTypes = {
  courseInfo: PropTypes.object,
  width: PropTypes.string,
  pd: PropTypes.string,
};
