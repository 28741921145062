import React from 'react';
import styled from 'styled-components';
import Icon from '@onedesign/icon';
import {
  useVideoInputs,
  useMeetingManager,
  useLocalVideo,
  useBackgroundBlur,
} from 'amazon-chime-sdk-component-library-react';
import OneClassBg from './assets/oneclass_bg.png';
import { LocalVideoV2, useMeetingDispatch, useMeetingState } from '@oneboard/meeting';
import NotOpen from 'components/NewOneBoard/VideoInfo/common/NotOpen';
import VideoWrapper from 'components/NewOneBoard/VideoInfo/common/VideoWrapper';
import DeviceSelection from './common/DeviceSelection';
import FeatureContainer from './common/FeatureContainer';
import BackgroundButton from './common/BackgroundButton';
import FeatureText from './common/FeatureText';
import TestContainer from './common/TestContainer';
import { CheckBox } from '@oneboard/ui-components';
import { t } from 'utils/i18n';

const ChangeBackground = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 8px;
`;

const videoInputConfig = {
  additionalDevices: true,
};

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

const StyledVideoWrapper = styled(VideoWrapper)`
  width: 261px;
  height: 171px;
`;

const FlipCheckBox = styled(CheckBox)`
  margin-top: 10px;
`;

const StyledDeviceSelection = styled(DeviceSelection)`
  margin: 8px 0;
  width: 100%;
  align-items: center;
`;

const VideoTestContainer = styled(TestContainer)`
  width: 323px;
`;

export const isOptionActive = (meetingManagerDeviceId, currentDeviceId) => {
  if (currentDeviceId === 'none' && meetingManagerDeviceId === null) {
    return true;
  }
  return currentDeviceId === meetingManagerDeviceId;
};

const VideoSetting = ({ activeEffect, setActiveEffect }) => {
  const { isVideoEnabled } = useLocalVideo();
  const { clearVideoBackgroundEffect, videoBlurBackgroundEffect, videoBackgroundImageEffect } = useMeetingDispatch();
  const { isBackgroundBlurSupported } = useBackgroundBlur();
  const effectClickHandler = (index) => {
    const effects = [clearVideoBackgroundEffect, videoBlurBackgroundEffect, videoBackgroundImageEffect];
    effects[index]();
    setActiveEffect(index);
  };

  const VideoInputControl = () => {
    const meetingManager = useMeetingManager();
    const { devices, selectedDevice } = useVideoInputs(videoInputConfig);

    const handleDeviceChange = async (event) => {
      const selectedDeviceId = event.target.value;
      if (selectedDeviceId === 'none') {
        await meetingManager.startVideoInputDevice(undefined);
      } else {
        await meetingManager.startVideoInputDevice(selectedDeviceId);
      }
    };
    return (
      <VideoTestContainer>
        <StyledDeviceSelection onChange={handleDeviceChange}>
          {devices.map((device) => (
            <option
              key={device.deviceId}
              value={device.deviceId}
              selected={isOptionActive(selectedDevice, device.deviceId)}
            >
              {device.label}
            </option>
          ))}
        </StyledDeviceSelection>
      </VideoTestContainer>
    );
  };
  const { toggleVideoIsMirroring } = useMeetingDispatch();
  const { context } = useMeetingState();
  const { videoIsMirroring } = context;

  const videoFlipHandler = (event) => {
    toggleVideoIsMirroring({ videoIsMirroring: event.target.checked });
  };

  return (
    <FeatureContainer>
      <VideoContainer>
        <CheckboxContainer>
          <StyledVideoWrapper>
            {isVideoEnabled ? <LocalVideoV2 videoIsMirroring={videoIsMirroring} /> : <NotOpen />}
          </StyledVideoWrapper>
          <FeatureText>{t('components.newOneBoard.footer.settingButton.videoSetting.camera', '攝影機')}</FeatureText>
          <VideoInputControl />
          <FlipCheckBox className='mirrorCheckbox' checked={videoIsMirroring} onChange={videoFlipHandler}>
            {t('components.newOneBoard.footer.settingButton.videoSetting.mirrorCode', '鏡像模式')}
          </FlipCheckBox>
        </CheckboxContainer>
        {isBackgroundBlurSupported && (
          <ChangeBackground>
            <FeatureText>{t('components.newOneBoard.footer.settingButton.videoSetting.changeBackground', '變更背景')}</FeatureText>
            <ButtonContainer>
              <BackgroundButton active={activeEffect === 0} onClick={() => effectClickHandler(0)}>
                <Icon name={'BanSolidGray'} size='md' color='#fff' />
              </BackgroundButton>
              <BackgroundButton active={activeEffect === 1} onClick={() => effectClickHandler(1)}>
                <Icon name={'UserBlurSolid'} size='md' color='#8B90A0' />
              </BackgroundButton>
              <BackgroundButton
                active={activeEffect === 2}
                onClick={() => effectClickHandler(2)}
                bgImage={OneClassBg}
              />
            </ButtonContainer>
          </ChangeBackground>
        )}
      </VideoContainer>
    </FeatureContainer>
  );
};

export default VideoSetting;
