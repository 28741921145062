import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@oneboard/ui-components';
import { useResourceInteractiveLearningModal } from 'providers/ResourceInteractiveLearningModalProvider';
import { getInteractiveLearning } from 'api/oneClub';
import { useWhiteboard } from '@oneboard/whiteboard';
import { IframeBridge } from '@oneboard/iframe-bridge';
import { useGroupContext } from 'providers/GroupProvider';
import { useCourseInfo } from 'providers/CourseInfoProvider';
import { API_STATUS } from 'constants/index';
import { notification } from 'antd';
import { v4 as uuid } from 'uuid';
import { useMaterialButton } from 'components/NewOneBoard/Footer/component/MaterialButton/MaterialButton';
import { StyledResourceModal, StyledResourceBox, StyledModalContent, StyledResourceItem } from './ResourceInteractiveLearningModal.style';

const ResourceInteractiveLearningModalContent = ({ className }) => {
  const { service: whiteboardService } = useWhiteboard();
  const { courseInfo } = useCourseInfo();
  const { closeModal: closeMaterialButtonModal } = useMaterialButton();
  const bookIds = courseInfo?.interactiveLearnings?.map(item => item.id)?.filter(id => id !== '') || [];
  const { addSceneQueue } = useGroupContext();
  const { toggleResourceInteractiveLearningModalSwitch, seriesData, currentBookId, setCurrentBook } = useResourceInteractiveLearningModal();
  const [resourceData, setResourceData] = useState();

  const closeModal = () => toggleResourceInteractiveLearningModalSwitch();

  const bookIdChangeHandler = async (resource) => {
    const { id, name: title, gameCode } = resource;
    const width = 1024;
    const height = 768;
    const DIR = `/interactive_learnings_${id}`;
    whiteboardService.putScenes(
      DIR,
      [{ name: '1' }]
    );
    whiteboardService.setScenePath(`${DIR}/1`);
    setCurrentBook(id);
    const url = `${process.env.REACT_APP_INTERACTIVE_LEARNING_DOMAIN}?gameCode=${gameCode}`;

    let bridge = await whiteboardService.getInvisiblePlugin(IframeBridge.kind);
    if (!bridge) {
      bridge = await IframeBridge.insert({
        room: whiteboardService,
        url, // iframe 的地址
        width,
        height,
        displaySceneDir: DIR,
        useSelector: true,
      });
    } else {
      bridge.setAttributes({
        url,
        width,
        height,
        displaySceneDir: DIR,
      });
    }
    bridge.scaleIframeToFit();
    whiteboardService.dispatchMagixEvent('changeBookId');
    addSceneQueue({ bookId: `interactive_learnings_${id}`, label: title, resourceName: title, width, height, name: '1' });
    closeModal();
    closeMaterialButtonModal();
  };

  useEffect(() => {
    const init = async () => {
      const { status, data, error } = await getInteractiveLearning({
        orderBy: 'desc',
        skip: 0,
        limit: 50,
        seriesCode: seriesData.code,
        fields: ['resourceTitle', 'gameCode']
      });

      if (error) {
        notification.error({
          className: 'customNotification',
          message: error?.message ?? '取得資料失敗',
          key: uuid()
        });
      }

      if (status === API_STATUS.SUCCESS && data) {
        const _resourceData = data.interactiveLearnings.map(resource => ({
          id: resource.id,
          name: resource.resourceTitle,
          bookId: resource.id,
          gameCode: resource.gameCode
        }));
        setResourceData(_resourceData);
      }
    };

    init();
  }, []);

  return (
    <StyledResourceModal className={className} data-testid="ResourceModal">
      <Modal width="600px" header="課程教材" type="white" onClose={closeModal} >
        <StyledModalContent>
          <StyledResourceBox>
            <div className="head">
              <div className="dot"></div>
              <div className="seriesTitle">{seriesData?.name}</div>
            </div>
            <div className="content">
              <div className="dottedLine"></div>
              <div className="list">
                {resourceData && resourceData.map(resource => (
                  <StyledResourceItem
                    key={resource.id}
                    className="resource"
                    active={currentBookId === resource.bookId}
                    onClick={() => bookIdChangeHandler(resource)}
                  >
                    <span>{resource.name}</span>
                    {bookIds.includes(resource.bookId) && <div className="label">課程教材</div>}
                  </StyledResourceItem>
                ))}
              </div>
            </div>
          </StyledResourceBox>
        </StyledModalContent>
      </Modal>
    </StyledResourceModal>
  );
};

ResourceInteractiveLearningModalContent.propTypes = {
  className: PropTypes.string,
};

export const ResourceInteractiveLearningModal = () => {
  const { resourceInteractiveLearningModalSwitch } = useResourceInteractiveLearningModal();

  if (!resourceInteractiveLearningModalSwitch) return null;

  return (
    <ResourceInteractiveLearningModalContent />
  );
};

ResourceInteractiveLearningModal.propTypes = {
  open: PropTypes.bool,
};