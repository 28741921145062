import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Tooltip, notification, Badge, Button } from 'antd';
import { IframeBridge } from '@oneboard/iframe-bridge';
import {
  useRosterState,
  useToggleLocalMute,
  useContentShareState,
  ContentShare,
  useAudioVideo,
} from 'amazon-chime-sdk-component-library-react';
import { useMeetingState, SettingPopoverButton, useMeetingDispatch } from '@oneboard/meeting';
import {
  TeachingMaterialContainer,
  AuditVideoList,
  SyncWhiteboard,
  SyncToolBox,
  SceneTab,
  RightContainer,
  PrivateMessageModal,
  PrivateMessageList,
  RecordButton,
} from 'containers';
import { MainLayout } from 'layouts';
import MainPageHeader from './Header';
import { Roles, NOTIFICATION_TYPE, NOTIFICATION_TYPE_KEY, FCM_TOPIC } from 'constants/index';
import { useNotification } from 'utils/hooks/useNotification';
import { usePrevious } from 'ahooks';
import { complementarySet } from 'utils/array';
import { Box, Modal } from '@oneboard/ui-components';
import { useViewMode } from 'utils/hooks/useViewMode';
import { useWhiteboard } from '@oneboard/whiteboard';
import PageController from '@netless/page-controller';
import PreviewController from '@netless/preview-controller';
import { useIframeBridge } from 'utils/hooks/useIframeBridge';
import Icon from '@onedesign/icon';
import { useGroupContext } from 'providers/GroupProvider';
import UsersModalMap from 'containers/UsersModal';
import { InsertPictureModal, ScenesImageUploadButton, SyncMainBlock, ResourceModal } from 'components';
import { useRoom } from 'providers/RoomProvider';
import {
  StyledSyncSingle,
  IconBox,
  ContentShareContainer,
  StyledPrivateMessageButton,
  StyleUsersModalLayout,
} from './SyncSingle.style';
// import { useUserAgent } from 'utils/hooks/useUserAgent';
// import { useFirebaseCloudMessage } from 'utils/hooks/useFirebaseCloudMessage';
// import { onMessage } from 'firebase/messaging';

export const SyncSingle = () => {
  const { meetingId } = useParams();
  const meetingState = useMeetingState();
  const audioVideo = useAudioVideo();
  // const { os, currentBrowser, currentUserAgent } = useUserAgent();
  // const { messaging, requestForToken } = useFirebaseCloudMessage();
  const { removeToolboxAuthAttendeeIds } = useMeetingDispatch();
  const { muted, toggleMute } = useToggleLocalMute();
  const { attendeeId: selfAttendeeId, toolboxAuthAttendeeIds, roomId } = meetingState.context;
  const { service: whiteboardService, addPage } = useWhiteboard();
  const { insertPictureModalSwitch } = useRoom();

  const [iframeGroup, setIframeGroup] = useState([]);
  // const [feedbackUrl, setFeedbackUrl] = useState('');
  const [feedbackNotify, setFeedbackNotify] = useState({
    isReceived: false,
    isRead: false,
  });
  const { openNotification } = useNotification();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const students = attendees.filter((attendee) => attendee.role === Roles.Student);
  const teacher = attendees.find((attendee) => attendee.role === Roles.Teacher);
  const studentsPrevious = usePrevious(students) || [];
  const [isShowPreviewMenu, setIsShowPreviewMenu] = useState(false);

  const [privateMessageModalState, setPrivateMessageModalState] = useState(false);
  const mainRef = useRef(null);

  const onPrivateMessageClose = () => setPrivateMessageModalState(false);

  useViewMode();
  useIframeBridge();

  const closeTeachingMaterialHandler = (material) => {
    switch (material.type) {
      case 'iframe':
        setIframeGroup((prev) => prev.filter((item) => item.sources !== material.sources));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!muted) {
      toggleMute();
    }
  }, []);

  const notificationStudentNumberChangeHandler = () => {
    const complementarySetResult = complementarySet(students, studentsPrevious);
    if (students.length > studentsPrevious.length) {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: NOTIFICATION_TYPE.ENTER,
          key: NOTIFICATION_TYPE_KEY.ENTER,
        })
      );
    } else {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: NOTIFICATION_TYPE.LEAVE,
          key: NOTIFICATION_TYPE_KEY.LEAVE,
        })
      );
    }
  };

  useEffect(() => {
    notificationStudentNumberChangeHandler();
  }, [students.length]);

  const { sharingAttendeeId } = useContentShareState();
  const contentSharingAttendeeId = useMemo(() => sharingAttendeeId?.split('#')[0] || '', [sharingAttendeeId]);
  const isShowContentShare = useMemo(
    () => contentSharingAttendeeId && contentSharingAttendeeId !== selfAttendeeId,
    [selfAttendeeId, contentSharingAttendeeId]
  );

  const { toggleUsersModal, usersModalState } = useGroupContext();

  const toggleUsersModalHandler = () => {
    toggleUsersModal();
  };

  useEffect(() => {
    if (!teacher) {
      onPrivateMessageClose();
    }
  }, [teacher]);

  const pagePreviewHandler = (state) => {
    setIsShowPreviewMenu(state);
  };

  const handleSceneUpload = () => {
    pagePreviewHandler(true);
    setTimeout(() => {
      const element = document.querySelector('nav.bm-item-list > .bm-item');
      element.scrollTop = element.scrollHeight;
    }, 100);
  };

  const insertFeedbackScene = async (url = '') => {
    if (!whiteboardService || !url) return;
    if (url && !feedbackNotify.isRead) {
      setFeedbackNotify((prev) => ({ ...prev, isRead: true }));
    }
    const cameraState = whiteboardService.state.cameraState;
    const width = cameraState.width;
    const height = cameraState.height;
    const courseId = roomId;
    const DIR = `/feedback_${courseId}`;
    whiteboardService.putScenes(DIR, [{ name: 1 }]);
    whiteboardService.setScenePath(`${DIR}/1`);

    // if (!studentId) return;

    let bridge = await whiteboardService.getInvisiblePlugin(IframeBridge.kind);
    if (!bridge) {
      bridge = await IframeBridge.insert({
        room: whiteboardService,
        url, // iframe 的地址
        width,
        height,
        displaySceneDir: DIR,
        useSelector: true,
      });
    } else {
      bridge.setAttributes({
        url,
        width,
        height,
        displaySceneDir: DIR,
      });
    }

    bridge.scaleIframeToFit();
  };

  const feedbackNotification = (url = '') => {
    if (!whiteboardService) return;
    notification.open({
      message: (
        <Box display='flex' width='100%' justifyContent='space-between' alignItems='center'>
          <Box>老師已完成課後回饋，快去查看吧！</Box>
          <Button onClick={() => insertFeedbackScene(url)}>立即查看</Button>
        </Box>
      ),
      className: 'notificationList',
      style: {
        width: whiteboardService.state.cameraState.width - 24 * 2,
      },
      duration: 30,
      placement: 'top',
      getContainer: () => mainRef.current,
    });
  };

  // 移除 ToolboxAuth
  useEffect(() => {
    if (!audioVideo) {
      return;
    }
    const rosterUpdateCallback = (chimeAttendeeId, present) => {
      if (!present) {
        const isEnableToolbox = toolboxAuthAttendeeIds.includes(chimeAttendeeId);
        if (isEnableToolbox) {
          removeToolboxAuthAttendeeIds({ attendeeId: chimeAttendeeId });
        }
        return;
      }
    };

    audioVideo.realtimeSubscribeToAttendeeIdPresence(rosterUpdateCallback);

    return () => {
      audioVideo.realtimeUnsubscribeToAttendeeIdPresence(rosterUpdateCallback);
    };
  }, [audioVideo, toolboxAuthAttendeeIds]);

  // // FCM 請求 token
  // useEffect(() => {
  //   if (!os || !currentBrowser || !currentUserAgent || !messaging) return;
  //   requestForToken({
  //     device: `${os};${currentBrowser};${currentUserAgent}`,
  //     topic: FCM_TOPIC.ONEBOARD_COURSE_FEEDBACK
  //   });
  // }, [os, currentBrowser, currentUserAgent, messaging]);
  // // 監聽 FCM 事件，課後回饋通知顯示。
  // if (messaging) {
  //   onMessage(messaging, (payload) => {
  //     /* 資料回傳格式
  //     {
  //       oneClubId,
  //       courseId,
  //       topic: "oneboardCourseFeedback",
  //       feedbackUrl: "https://live-dev.oneclass.com.tw/633bde32f6cf4a48317e5500/feeback/639297a8d1e28459e9a3859a"
  //     } */
  //     console.log('messages', payload);
  //     if (payload.data
  //       && meetingId === payload.data?.courseId
  //       && payload.data?.topic === FCM_TOPIC.ONEBOARD_COURSE_FEEDBACK ) {
  //       const url = payload.data.feedbackUrl;
  //       setFeedbackNotify(() => ({ isRead: false, isReceived: true }));
  //       setFeedbackUrl(url);
  //       feedbackNotification(url);
  //     }
  //   });
  // }
  return (
    <StyledSyncSingle>
      <MainLayout
        right={
          <RightContainer>
            <MainPageHeader />
          </RightContainer>
        }
        mode='single'
      >
        <SyncMainBlock ref={mainRef}>
          <PrivateMessageList filterRole={Roles.Advisor} containerRef={mainRef} />
          {whiteboardService && <SceneTab room={whiteboardService} />}
          <SyncWhiteboard toolBox={<SyncToolBox meetingId={meetingId} />}>
            <Box position='absolute' zIndex={50} bottom='96px' right={2} mb={2}>
              {teacher && (
                <Tooltip placement='top' title='私訊老師'>
                  <StyledPrivateMessageButton onClick={() => setPrivateMessageModalState(true)}>
                    <Icon name='WalkieTalkie' size='xl' color='#EC7963' />
                  </StyledPrivateMessageButton>
                </Tooltip>
              )}
            </Box>
            <Box position='absolute' zIndex={50} bottom='40px' right={2}>
              <RecordButton
                mode='light'
                placement='leftBottom'
                content='顧問談單請務必錄影，未提供錄影檔業績將不列入計算！'
              />
            </Box>
            <Box display='flex' position='absolute' zIndex={5} bottom={2} right={2}>
              {/* {
              feedbackNotify.isReceived && (
                <Box ml={2}>
                  <Tooltip placement="top" title='查看課後回饋'>
                    <Badge dot={!feedbackNotify.isRead}>
                      <IconBox onClick={() => insertFeedbackScene(feedbackUrl)}>
                        <Icon name="CommentSmileSolid" size="xs" />
                      </IconBox>
                    </Badge>
                  </Tooltip>
                </Box> )
            } */}
              <Box ml={2}>
                <IconBox onClick={toggleUsersModalHandler}>
                  <Icon name='UsersSolid' />
                </IconBox>
              </Box>
              <Box ml={2}>
                <IconBox>
                  <SettingPopoverButton className='settingPopoverButton' placement='leftTop' disabledPopover />
                </IconBox>
              </Box>
              <Box ml={2}>
                <ScenesImageUploadButton service={whiteboardService} onUploaded={handleSceneUpload}>
                  <IconBox>
                    <Icon name='ImageSolid' size='xs' />
                  </IconBox>
                </ScenesImageUploadButton>
              </Box>
              <Box ml={2}>
                <IconBox onClick={() => pagePreviewHandler(true)}>
                  <Icon name='ThSolid' size='xs' />
                </IconBox>
              </Box>
              <Box ml={2}>
                <IconBox onClick={addPage}>
                  <Icon name='FileAddSolid' size='xs' />
                </IconBox>
              </Box>
              <Box ml={2}>
                <PageController room={whiteboardService} />
              </Box>
            </Box>
            <PreviewController
              room={whiteboardService}
              isVisible={isShowPreviewMenu}
              handlePreviewState={pagePreviewHandler}
            />
          </SyncWhiteboard>

          {isShowContentShare && (
            <ContentShareContainer>
              <ContentShare />
            </ContentShareContainer>
          )}

          <TeachingMaterialContainer iframeGroup={iframeGroup} onClose={closeTeachingMaterialHandler} />
          <div className='auditListLayout'>
            <AuditVideoList />
          </div>

          {teacher && privateMessageModalState && (
            <Modal width='600px' header='提示訊息' onClose={onPrivateMessageClose}>
              <PrivateMessageModal
                onClose={onPrivateMessageClose}
                targetRole={Roles.Teacher}
                placeholder='在這裡輸入提示訊息，傳送給授課老師。'
              />
            </Modal>
          )}

          {insertPictureModalSwitch && <InsertPictureModal />}

          <StyleUsersModalLayout active={usersModalState}>
            <UsersModalMap.AdvisorUsersModal onClose={toggleUsersModalHandler} />
          </StyleUsersModalLayout>
        </SyncMainBlock>
      </MainLayout>
    </StyledSyncSingle>
  );
};
