import React, { useState } from 'react';
import styled from 'styled-components';
import { ViewMode } from 'white-react-sdk';
import { useWhiteboard } from '@oneboard/whiteboard';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import Icon from '@onedesign/icon';
import { t } from 'utils/i18n';

const antIcon = <LoadingOutlined style={{ fontSize: 15, color: '#303030' }} spin />;

const Button = styled.button`
  display: flex;
  justify-content: center;
  width: 64px;
  padding: 4px 4px 4px 4px;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
  border: 0;
  margin-right: 8px;
  cursor: pointer;
`;

const ButtonText = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;

  :hover {
    border-radius: 2px;
    background-color: rgba(33, 35, 36, 0.1);
  }
`;

export default function BroadcastButton() {
  const { service: whiteboardService } = useWhiteboard();
  const currentViewMode = whiteboardService?.state?.broadcastState?.mode;
  const [loading, setLoading] = useState(false);

  const getBroadcasterMode = () => {
    if (!currentViewMode && !whiteboardService) return;
    setLoading(true);

    if (currentViewMode !== ViewMode.Broadcaster) {
      whiteboardService.setViewMode(ViewMode.Broadcaster);
    }

    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  return (
    <Button onClick={getBroadcasterMode}>
      {loading ? (
        <Spin indicator={antIcon} />
      ) : (
        <ButtonText>
          <Icon name='LightRefresh' size='md' color={'#444E60'} />
          {t('components.newOneBoard.whiteboard.refresh', '刷新')}
        </ButtonText>
      )}
    </Button>
  );
}
