import {
  createContext,
  useContext,
  useState,
  useMemo
} from 'react';
import PropTypes from 'prop-types';

const ResourceInteractiveLearningModalContext = createContext(null);

const ResourceInteractiveLearningModalProvider = ({ children }) => {
  const [resourceInteractiveLearningModalSwitch, setResourceInteractiveLearningModalSwitch] = useState(false);
  const [seriesData, setSeriesData] = useState();
  const [currentBookId, setCurrentBook] = useState();

  const toggleResourceInteractiveLearningModalSwitch = () => setResourceInteractiveLearningModalSwitch(prev => !prev);


  const value = useMemo(() => ({
    seriesData,
    setSeriesData,
    currentBookId,
    setCurrentBook,
    resourceInteractiveLearningModalSwitch,
    toggleResourceInteractiveLearningModalSwitch
  }),
    [
      seriesData,
      currentBookId,
      resourceInteractiveLearningModalSwitch,
      toggleResourceInteractiveLearningModalSwitch
    ]
  );

  return (
    <ResourceInteractiveLearningModalContext.Provider value={value}>
      {children}
    </ResourceInteractiveLearningModalContext.Provider>
  );
};

ResourceInteractiveLearningModalProvider.propTypes = {
  children: PropTypes.node,
};

const useResourceInteractiveLearningModal = () => {
  const context = useContext(ResourceInteractiveLearningModalContext);
  if (!context) {
    throw new Error('useResourceInteractiveLearningModal must be used within a ResourceInteractiveLearningModalProvider');
  }

  return context;
};

export { ResourceInteractiveLearningModalProvider, useResourceInteractiveLearningModal };