import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Insert_Picture_State, Insert_Picture_Event } from 'machines/InsertPictureMachine';
import { useLocalVideo, useAudioVideo, useSelectVideoQuality } from 'amazon-chime-sdk-component-library-react';
import { LocalVideo, useMeetingState } from '@oneboard/meeting';
import { useCountDown, useLocalStorageState } from 'ahooks';
import { useRoom } from 'providers/RoomProvider';
import Icon from '@onedesign/icon';
import { useS3 } from 'utils/hooks/useS3';
import { useImage } from 'utils/hooks/useImage';
import { v4 as uuid } from 'uuid';
import { useWhiteboard } from '@oneboard/whiteboard';
import { message } from 'antd';
import { Box } from '@oneboard/ui-components';
import { useInsertPicture } from 'providers/InsertPictureProvider';
import Buffer from 'buffer';
import {
  StyledInsertPictureModal,
  StyledUploadIconBox,
  SnapButtonBox,
  OperateButton,
  StyledVideoContent,
  StyledVideoBox,
  StyledCountdown,
  StyledNoticeTip,
  StyledImgBox,
} from './InsertPictureModal.style';
import { t } from '../../utils/i18n';
import { Trans } from 'react-i18next';
import ToolModal from 'components/NewOneBoard/common/ToolModal';
import styled from 'styled-components';
import { Roles, ClassType } from 'constants/index';

const OrText = styled.span`
  color: #637381;
  font-size: 20px;
  margin: 0 32px;
`;

const CameraText = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #919eab;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoticeTip = ({ storageHandle, closeHandle }) => {
  const knowHandle = () => {
    storageHandle();
    closeHandle();
  };

  return (
    <StyledNoticeTip>
      <div className='head'>
        <div className='title'>{t('containers.insertPictureModal.photoProcess', '拍照流程')}</div>
        <div className='close' onClick={closeHandle}>
          <Icon name='XmarkOutline' size='xs' />
        </div>
      </div>
      <ul>
        <li>{t('containers.insertPictureModal.takeTestPaperPhoto', '請將考卷舉起並對準鏡頭')}</li>
        <li>
          <Trans i18nKey='containers.insertPictureModal.pressToCountDown'>
            按下「
            <Icon name='CameraSolid' size='sm' />
            」，倒數五秒
          </Trans>
        </li>
        <li>
          <Trans i18nKey='containers.insertPictureModal.sendToWhiteboard'>
            點選「
            <Icon name='PaperPlaneSolid' size='sm' />
            」傳送至白板
          </Trans>
        </li>
      </ul>
      <Box display='flex' alignItems='center' justifyContent='flex-end' mt={3}>
        <button className='knowButton' onClick={knowHandle}>
          {t('containers.insertPictureModal.understand', '我知道了')}
        </button>
      </Box>
    </StyledNoticeTip>
  );
};

NoticeTip.propTypes = {
  storageHandle: PropTypes.func,
  closeHandle: PropTypes.func,
};

const picture_params = {
  width: 1280,
  height: 720,
};

export const InsertPictureModal = ({ className }) => {
  const { meetingId } = useParams();
  const audioVideo = useAudioVideo();
  const { service: whiteboardService } = useWhiteboard();
  const { tileId, toggleVideo, isVideoEnabled } = useLocalVideo();
  const { sendImage, receiveImageStatus } = useImage();
  const selectVideoQuality = useSelectVideoQuality();
  const { toggleInsertPictureModalSwitch } = useRoom();
  const inputFileRef = useRef();
  const canvasRef = useRef();
  const { upload } = useS3(meetingId);
  const [tipStorage, setTipStorage] = useLocalStorageState('notice_tip');
  const [tipSwitch, setTipSwitch] = useState(false);
  const [imageData, setImageData] = useState();
  const imageRef = useRef();
  const [imageUrl, setImageUrl] = useState();
  const { state: insertPictureState, send: insertPictureSend } = useInsertPicture();
  const meetingState = useMeetingState();
  const { context } = meetingState;
  const { role, courseType } = context;

  const closeTip = () => setTipSwitch(false);
  const [targetDate, setTargetDate] = useState();
  const [countdown] = useCountDown({
    targetDate,
    onEnd: () => {
      const imageData = audioVideo.captureVideoTile(tileId);
      setImageData(imageData);
      insertPictureSend({
        type: Insert_Picture_Event.Take_photo,
      });
    },
  });

  const isIdle = insertPictureState.matches(Insert_Picture_State.Idle);
  const isReady = insertPictureState.matches(Insert_Picture_State.Ready);
  const isCountdown = insertPictureState.matches(Insert_Picture_State.Countdown);
  const isPreview = insertPictureState.matches(Insert_Picture_State.Preview);

  const handleUploadSuccess = async (file, filename) => {
    try {
      const { url } = await upload(file, file.type, filename);
      const img = new Image();
      img.onload = () => {
        const imageInformation = {
          uuid: uuid(),
          centerX: 0,
          centerY: 0,
          width: img.width,
          height: img.height,
          locked: false,
        };
        if (role === Roles.Student && courseType === ClassType.SyncMultiple) {
          return;
        } else {
          whiteboardService.insertImage(imageInformation);
          whiteboardService.completeImageUpload(imageInformation.uuid, url);
        }
      };
      img.src = url;
      return { success: true, url };
    } catch (error) {
      console.error('Upload error:', error);
      return { success: false };
    }
  };

  const insertImageHandler = async (event) => {
    const files = event.target.files;

    if (files.length > 20) {
      message.error(t('containers.insertPictureModal.uploadLimit', '最多上傳 20 張'), 3);
      return;
    }

    const uploadPromises = Array.from(files).map(async (file) => {
      const filename = `${new Date().getTime()}${file.name.split('.').pop()}`;
      if (role === Roles.Student) {
        sendImage(file, filename);
      }
      return handleUploadSuccess(file, filename);
    });

    const results = await Promise.all(uploadPromises);

    closeChooseUploadTypeModal();

    if (role === Roles.Student && courseType === ClassType.SyncMultiple) {
      message.info({
        content: t('containers.insertPictureModal.teacherReviewing', '老師正在審核你的圖片'),
        duration: 3,
        key: '審核中',
      });
      return;
    } else {
      message.loading({
        content: t('containers.insertPictureModal.fileUploading', '檔案上傳中...'),
        duration: 3,
        key: '檔案上傳',
      });
    }
    const successUploads = results.filter((result) => result.success);
    if (successUploads.length > 0) {
      message.success(
        t('containers.insertPictureModal.uploadSuccess', '上傳成功')
      );
    } else {
      message.error(
        t('containers.insertPictureModal.uploadFailed', '上傳失敗')
      );
    }

    event.target.value = null;
  };

  const inputFileHandle = () => inputFileRef.current.click();

  const toReadyStepHandle = () => {
    if (isVideoEnabled) {
      toggleVideo();
    }

    selectVideoQuality('720p');
    insertPictureSend({
      type: Insert_Picture_Event.Ready,
    });
  };

  // 開始拍照
  const startHandle = () => {
    insertPictureSend({
      type: Insert_Picture_Event.Countdown,
    });

    setTargetDate(Date.now() + 5000);
  };

  // 重拍
  const turnBackHandle = () => {
    insertPictureSend({
      type: Insert_Picture_Event.Turn_back,
    });
  };

  // canvas 上傳
  const canvasInsertImageHandle = () => {
    const id = uuid();
    const buf = Buffer.Buffer.from(imageUrl.replace(/^data:image\/\w+;base64,/, ''), 'base64');

    const bufferObj = {
      name: id,
      buf,
    };

    message.loading({
      content: t('containers.insertPictureModal.fileUploading', '檔案上傳中...'),
      duration: 0,
      key: bufferObj.name,
    });

    upload(bufferObj, 'buffer', bufferObj.name).then((res) => {
      const uploadFile = res;
      const { state } = uploadFile;
      if (state === 'success') {
        const { url } = uploadFile;

        const img = new Image();
        img.onload = () => {
          message.destroy(bufferObj.id);
          message.success(
            t('containers.insertPictureModal.uploadSuccess', '上傳成功')
          );
          const id = uuid();
          const imageInformation = {
            uuid: id,
            centerX: 0,
            centerY: 0,
            width: img.width,
            height: img.height,
            locked: false,
          };

          whiteboardService.insertImage(imageInformation);
          whiteboardService.completeImageUpload(id, url);

          closePhotographModal();
        };
        img.src = url;
      } else {
        message.destroy(bufferObj.id);
        message.error('error upload');
      }
    });
  };

  const canvasToImgHandle = () => {
    if (!isPreview) return;
    if (!canvasRef.current) return;

    const context = canvasRef.current.getContext('2d');
    context.clearRect(0, 0, picture_params.width, picture_params.height);
    context.putImageData(imageData, 0, 0);
    const dataURL = canvasRef.current.toDataURL('image/png');
    setImageUrl(dataURL);
  };

  const closeChooseUploadTypeModal = () => toggleInsertPictureModalSwitch();

  const closePhotographModal = () => {
    toggleVideo();
    selectVideoQuality('360p');

    toggleInsertPictureModalSwitch();
    insertPictureSend({
      type: Insert_Picture_Event.Close,
    });
  };

  useEffect(() => {
    canvasToImgHandle();
  }, [canvasToImgHandle]);

  useEffect(() => {
    if (tipStorage) return;
    setTipSwitch(true);
  }, [tipStorage]);

  useEffect(() => {
    if (isReady && !isVideoEnabled) {
      setTimeout(toggleVideo, 1000);
    }
  }, [isReady, isVideoEnabled]);

  useEffect(() => {
    const handleImageStatus = (statusMessage) => {
      if (statusMessage.status === 'agree') {
        message.success({
          content: t('containers.insertPictureModal.imageApproved', '圖片已審核通過，正在上傳中...'),
          duration: 3,
          key: '審核通過',
        });
      } else if (statusMessage.status === 'disagree') {
        message.error({
          content: t('containers.insertPictureModal.imageRejected', '圖片未通過審核，請重新上傳'),
          duration: 3,
          key: '審核未通過',
        });
      }
    };
    receiveImageStatus(handleImageStatus);
  }, [receiveImageStatus]);

  return (
    <StyledInsertPictureModal className={className} data-testid='InsertPictureModal'>
      {isIdle && (
        <ToolModal
          active={isIdle}
          onClose={closeChooseUploadTypeModal}
          title={t('containers.insertPictureModal.uploadMethod', '上傳方式')}
          width='600px'
          height='350px'
          showBottomContainer={false}
        >
          <Box display='flex' alignItems='center' justifyContent='center' width='100%'>
            <StyledUploadIconBox onClick={inputFileHandle}>
              <Icon name='ImageSolid' width={64} />
              <div className='text'>{t('containers.insertPictureModal.picture', '圖片')}</div>
            </StyledUploadIconBox>
            <OrText mx={38}>{t('containers.insertPictureModal.or', '或')}</OrText>

            <StyledUploadIconBox onClick={toReadyStepHandle}>
              <Icon name='CameraSolid' width={64} />
              <div className='text'>{t('containers.insertPictureModal.camera', '照相機')}</div>
            </StyledUploadIconBox>
          </Box>
        </ToolModal>
      )}

      {(isReady || isCountdown) && (
        <ToolModal
          active={isReady || isCountdown}
          onClose={closePhotographModal}
          title={t('containers.insertPictureModal.camera', '照相機')}
          width='712px'
          height='530px'
          showBottomContainer={false}
        >
          <StyledVideoContent>
            <StyledVideoBox>
              <LocalVideo />
            </StyledVideoBox>
            {isReady && (
              <SnapButtonBox onClick={startHandle} color='#fff'>
                <div className='snapButton'>
                  <div className='icon'>
                    <Icon name='CameraSolid' width={48} />
                  </div>
                </div>
              </SnapButtonBox>
            )}
            {isCountdown && <StyledCountdown count={Math.round(countdown / 1000)} />}
            {tipSwitch && <NoticeTip storageHandle={() => setTipStorage(true)} closeHandle={closeTip} />}
          </StyledVideoContent>
          <CameraText>
            {t(
              'containers.insertPictureModal.ensureUserWhileTakingPicture',
              '拍照過程中，文字相反是正常現象。拍完照後文字就會轉正囉~'
            )}
          </CameraText>
        </ToolModal>
      )}

      {isPreview && (
        <ToolModal
          active={isPreview}
          onClose={closePhotographModal}
          title={t('containers.insertPictureModal.camera', '照相機')}
          width='712px'
          height='438px'
          showBottomContainer={false}
        >
          <div className='canvasBox'>
            <canvas ref={canvasRef} width='1280' height='720' />
          </div>
          <StyledImgBox>
            <img ref={imageRef} src={imageUrl} alt='' />
          </StyledImgBox>
          <Box display='flex' alignItems='center' justifyContent='center' width='100%' height='100%' mt={3}>
            <OperateButton type='secondary' color='#637381' backgroundColor='#637381' onClick={turnBackHandle}>
              <div className='icon'>
                <Icon name='TurnbackArrowSolid' size='md' color='#ffffff' />
              </div>
              <div className='text'>{t('containers.insertPictureModal.retake', '重拍')}</div>
            </OperateButton>
            <OperateButton type='secondary' color='#EC7963' backgroundColor='#EC7963' onClick={canvasInsertImageHandle}>
              <div className='icon'>
                <Icon name='PaperPlaneSolid' size='md' color='#ffffff' />
              </div>
              <div className='text'>{t('containers.insertPictureModal.send', '傳送')}</div>
            </OperateButton>
          </Box>
        </ToolModal>
      )}
      <input hidden type='file' accept='image/*' ref={inputFileRef} onChange={insertImageHandler} multiple />
    </StyledInsertPictureModal>
  );
};

InsertPictureModal.propTypes = {
  className: PropTypes.string,
};
