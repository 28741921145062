import React, { useRef } from 'react';
import moment from 'moment';
import {
  StyledWaitingAnimation,
  Animation,
  AnimationWrap,
  TextContainer,
  StyledHeader,
  StyledText,
} from './CourseCompleted.style';
import Close from './Close.gif';
import { SendButton } from 'components/NewOneBoard/common/MessageButton';
import { t } from 'utils/i18n';

export const CourseCompleted = ({ startTime, endTime, courseType }) => {
  const ref = useRef();
  const handleOpenLiveHomepage = () => {
    window.open('https://live.oneclass.com.tw/home');
  };

  const formattedStartTime = moment(startTime).format('YYYY/MM/DD HH:mm');
  const formattedEndTime = moment(endTime).format('YYYY/MM/DD HH:mm');
  const courseTime = `${formattedStartTime} - ${formattedEndTime}`;

  return (
    <StyledWaitingAnimation>
      <AnimationWrap ref={ref}>
        <Animation ref={ref}>
          <img width={'200px'} src={Close} alt='Time' />
        </Animation>
        <TextContainer>
          <StyledHeader>{t('components.courseCompleted.title', '課程已結束')}</StyledHeader>
          <StyledText>
            {t('components.courseCompleted.courseTime', '課程時間：')}{courseTime}
          </StyledText>
        </TextContainer>
        {(courseType === 'individualLiveCourse' || courseType === 'groupLiveCourse') && (
          <SendButton onClick={handleOpenLiveHomepage}>
            {t('components.courseCompleted.backToHome', '回到首頁')}
          </SendButton>
        )}
      </AnimationWrap>
    </StyledWaitingAnimation>
  );
};
