import React, { useState, useCallback, useMemo, createRef, useEffect, useRef } from 'react';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import PropTypes from 'prop-types';
import { Select, Box, CheckBox } from '@oneboard/ui-components';
import { useQuizService } from 'utils/hooks/useQuiz';
import { OptionView } from './OptionView';
import {
  StyledQuizModal,
  StyledOptionSelectView,
  StyledQuizProgressingView,
  StyledUserList,
  StatusBox,
  StyledHeader,
  StyledCloseModal,
} from './QuizModal.style';
import { Roles } from 'constants/index';
import { ResizableRect } from 'components';
import Icon from '@onedesign/icon';
import { useSize } from 'ahooks';
import { CancelButton, SendButton } from 'components/NewOneBoard/common/MessageButton';
import styled from 'styled-components';
import { t } from 'utils/i18n';

const ButtonContainer = styled(Box)`
  display: flex;
  height: 68px;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;
  align-items: flex-end;
`;

const CheckoutContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Title = styled.p`
  margin: 0;
  color: #919eab;
  margin-bottom: 14px;
`;

const ModalBackground = styled.div`
  display: ${({ active }) => (active ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
`;

const Options = [1, 2, 3, 4, 5];

const OptionSelectView = () => {
  const { closeQuiz, startQuiz } = useQuizService();
  const [count, setCount] = useState(null);

  const startHandler = useCallback(
    (count) => () => {
      startQuiz({ count });
    },
    []
  );

  return (
    <StyledOptionSelectView>
      <div className='options'>
        <div className='label'>{t('containers.quizModal.optionCount', '選項數量')}</div>
        <Select
          placeholder={t('containers.quizModal.placeholder', '請選擇')}
          onChange={setCount}>
          {Options.map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Box className='preview'>
        <OptionView count={count} />
      </Box>
      <div className='action'>
        <ButtonContainer>
          <CancelButton block size='large' onClick={closeQuiz}>
            {t('containers.quizModal.cancel', '取消')}
          </CancelButton>
          <SendButton block size='large' disabled={!count} onClick={startHandler(count)}>
            {t('containers.quizModal.start', '開始')}
          </SendButton>
        </ButtonContainer>
      </div>
    </StyledOptionSelectView>
  );
};

const CheckListBox = () => {
  const { endQuiz } = useQuizService();

  return <SendButton onClick={endQuiz}>
    {t('containers.quizModal.endQuiz', '結束作答')}
  </SendButton>;

};

const CorrectStatusBox = ({ disabled, count, onCheck, checked }) => {
  return (
    <StatusBox className='correctStatus' width='120px' disabled={disabled}>
      <Box className='count'>
        <span>{disabled ? '-' : count}</span>
        {!disabled && <CheckBox onChange={(e) => onCheck(e.target.checked)} checked={checked} />}
      </Box>
      {t('containers.quizModal.correctCount', '答對人數')}
    </StatusBox>
  );
};

CorrectStatusBox.propTypes = {
  disabled: PropTypes.bool,
  count: PropTypes.number,
  onCheck: PropTypes.func,
  checked: PropTypes.bool,
};

const CorrectAnswerBox = ({ value, onChange }) => {
  const { quizContext, reviewQuiz } = useQuizService();
  const { optionCount } = quizContext;

  const changeHandler = (value) => {
    onChange(value);
    reviewQuiz();
  };

  return (
    <Box className='correctAnswerBox'>
      <Title my={3}>{t('containers.quizModal.correctAnswer', '正確答案')}</Title>
      <div className='options'>
        <OptionView count={optionCount} clickable value={value} onChange={changeHandler} />
      </div>
    </Box>
  );
};

CorrectAnswerBox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

const AnswerStatus = ({ answer, showAnswer }) => {
  if (!answer) return <span style={{ color: '#EC7963' }}>{t('containers.quizModal.notAnswered', '未作答')}</span>;
  if (!showAnswer && answer) return <span>{t('containers.quizModal.answered', '已作答')}</span>;
  if (showAnswer && answer) return <span style={{ color: '#07B2D8', fontSize: '16px' }}>{answer}</span>;
};

AnswerStatus.propTypes = {
  answer: PropTypes.any,
  showAnswer: PropTypes.bool,
};

const QuizProgressingView = () => {
  const { States, teacherQuizStateMatches, quizContext } = useQuizService();
  const { roster } = useRosterState();
  const students = useMemo(() => Object.values(roster).filter((attendee) => attendee.role === Roles.Student), [roster]);

  const { attendeeAnswers } = quizContext;
  const answers = Object.values(attendeeAnswers);
  const checkAnswer = (answers) => answers.filter((answer) => answer === correctAnswer);

  const [correctAnswer, setCorrectAnswer] = useState();
  const [showCorrectAnswerOnly, setShowCorrectAnswerOnly] = useState(false);

  const studentsWithAnswer = useMemo(
    () =>
      students
        .map((student) => ({
          ...student,
          answer: attendeeAnswers[student.chimeAttendeeId] || null,
        }))
        .filter((student) => (showCorrectAnswerOnly ? student.answer === correctAnswer : true)),
    [students, showCorrectAnswerOnly, correctAnswer, attendeeAnswers]
  );

  return (
    <StyledQuizProgressingView>
      <div className='content'>
        <Box className='userListWrapper' width='60%'>
          <StyledUserList>
            <div className='listHeader'>
              <span>{t('containers.quizModal.name', '姓名')}</span>
              <span>{t('containers.quizModal.answerStatus', '答題狀態')}</span>
            </div>
            <div className='listBody'>
              {studentsWithAnswer.map((student) => (
                <div className='listItem'>
                  <span>{student?.name}</span>
                  <AnswerStatus
                    showAnswer={[States.End, States.Reviewing].some(teacherQuizStateMatches)}
                    answer={student.answer}
                  />
                </div>
              ))}
            </div>
          </StyledUserList>
        </Box>
        <Box
          className='statusBoxWrapper'
          width='256px'
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
        >
          <div className='status'>
            <Title>{t('containers.quizModal.classStatus', '班級答題狀態')}</Title>
            <Box display='flex' justifyContent='space-between'>
              <StatusBox width='120px'>
                <Box className='count'>
                  <span>{answers.length}</span>/{students.length}
                </Box>
                {t('containers.quizModal.answeredCount', '作答人數')}
              </StatusBox>
              <CorrectStatusBox
                count={checkAnswer(answers).length}
                disabled={!correctAnswer}
                checked={showCorrectAnswerOnly}
                onCheck={setShowCorrectAnswerOnly}
              />
            </Box>
          </div>
          <div className='actions'>
            {teacherQuizStateMatches(States.Starting) ? (
              <CheckoutContainer>
                <CheckListBox />
              </CheckoutContainer>
            ) : [States.End, States.Reviewing].some(teacherQuizStateMatches) ? (
              <CorrectAnswerBox onChange={setCorrectAnswer} value={correctAnswer} />
            ) : null}
          </div>
        </Box>
      </div>
    </StyledQuizProgressingView>
  );
};

const TeacherQuizModalView = () => {
  const { States, teacherQuizStateMatches } = useQuizService();

  const isQuizProgressing = [States.Starting, States.End, States.Reviewing].some(teacherQuizStateMatches);

  return (
    <>
      {teacherQuizStateMatches(States.Setting) ? (
        <OptionSelectView />
      ) : isQuizProgressing ? (
        <QuizProgressingView />
      ) : null}
    </>
  );
};

const StudentQuizModal = () => {
  const { attendeeId, quizContext, answerQuiz } = useQuizService();
  const { optionCount } = quizContext;
  const [answer, setAnswer] = useState();

  const submitHandler = () => {
    answerQuiz({
      attendeeId,
      answer,
    });
  };

  return (
    <div className='options'>
      <OptionView count={optionCount} clickable value={answer} onChange={setAnswer} />
      <ButtonContainer>
        <SendButton onClick={submitHandler} disabled={!answer}>
          {t('containers.quizModal.submit', '送出')}
        </SendButton>
      </ButtonContainer>
    </div>
  );
};

export const QuizModal = ({ role }) => {
  const { closeQuiz, teacherQuizStateMatches, States } = useQuizService();

  const isTeacher = role === Roles.Teacher;
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const resizableRef = createRef();
  const [parentDom, setParentDom] = useState(null);
  const parentSize = useSize(parentDom);
  const wrapperRef = useRef();
  const wrapperSize = useSize(wrapperRef.current);
  const [modalPosition, setModalPosition] = useState();

  const showConfirmModalHandler = () => {
    [States.Starting, States.End].some(teacherQuizStateMatches) ? setShowConfirmModal(true) : closeQuiz();
  };

  const hideConfirmModalHandler = () => setShowConfirmModal(false);
  const closeHandler = () => {
    isTeacher && closeQuiz();
  };

  useEffect(() => {
    if (!resizableRef.current || !wrapperRef.current) return;

    setParentDom(resizableRef.current.parentNode);
  }, [resizableRef, wrapperRef]);

  useEffect(() => {
    if (!parentSize || !wrapperSize || !wrapperSize?.width || !parentSize?.width) return;

    setModalPosition({
      top: parentSize.height / 2 - wrapperSize.height / 2,
      left: parentSize.width / 2 - wrapperSize.width / 2,
    });
  }, [wrapperSize, parentSize]);

  return (
    <ModalBackground active={true}>
      <ResizableRect ref={resizableRef} resizable={false} isBorder={false}>
        {(ref) => (
          <StyledQuizModal
            showConfirmModal={showConfirmModal}
            ref={ref}
            position={modalPosition}
            data-testid='QuizModal'
          >
            {!showConfirmModal ? (
              <Box
                className='wrapper'
                ref={wrapperRef}
                style={{
                  height: !isTeacher && '220px',
                  width: !isTeacher && '412px',
                }}
              >
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <StyledHeader>{t('containers.quizModal.title', '答題小工具')}</StyledHeader>
                  {isTeacher && (
                    <Box className='closeButton' onClick={showConfirmModalHandler}>
                      <Icon name='XmarkOutline' size='xs' />
                    </Box>
                  )}
                </Box>
                <div className='body'>{isTeacher ? <TeacherQuizModalView /> : <StudentQuizModal />}</div>
              </Box>
            ) : (
              <StyledCloseModal>
                <Box className='wrapper'>
                  <Box display='flex' alignItems='center' justifyContent='space-between' mb={3}>
                    <StyledHeader>{t('containers.quizModal.closeTitle', '關閉答題工具')}</StyledHeader>
                    {isTeacher && (
                      <Box className='closeButton' onClick={hideConfirmModalHandler}>
                        <Icon name='XmarkOutline' size='xs' color='#637381' />
                      </Box>
                    )}
                  </Box>
                  <div className='body'>
                    {t('containers.quizModal.confirmClose', '確定結束此次作答嗎？')}
                    <ButtonContainer>
                      <CancelButton onClick={hideConfirmModalHandler}>
                        {t('containers.quizModal.cancel', '取消')}
                      </CancelButton>
                      <SendButton onClick={closeHandler}>
                        {t('containers.quizModal.confirm', '確定結束')}
                      </SendButton>
                    </ButtonContainer>
                  </div>
                </Box>
              </StyledCloseModal>
            )}
          </StyledQuizModal>
        )}
      </ResizableRect>
    </ModalBackground>
  );
};

QuizModal.propTypes = {
  role: PropTypes.string,
};
