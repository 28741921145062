import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useLocalVideo,
  useRosterState,
  useToggleLocalMute,
  useContentShareState,
  ContentShare,
  useRemoteVideoTileState,
} from 'amazon-chime-sdk-component-library-react';
import { useMeetingState, useMeetingDispatch } from '@oneboard/meeting';
import { Roles, ClassType, NOTIFICATION_TYPE_I18N, NOTIFICATION_TYPE_KEY } from 'constants/index';
import { useNotification } from 'utils/hooks/useNotification';
import { usePrevious } from 'ahooks';
import { complementarySet } from 'utils/array';
import { useWhiteboard } from '@oneboard/whiteboard';
import { useZoomControl } from 'utils/hooks/useZoomControl';
import { getMultiUserRecord } from 'services/userRecord';
import { ContentShareContainer } from './SyncObserver.style';
import MainLayoutV2 from 'layouts/MainLayoutV2';
import styled from 'styled-components';
import Observer from 'components/NewOneBoard/VideoInfo/Observer';
import Whiteboard from 'components/NewOneBoard/Whiteboard';
import ToolBox from 'components/NewOneBoard/ToolBox';
import { TeachingMaterialContainer, SyncVideoListV2 } from 'containers';
import AdvisorContainer from 'components/NewOneBoard/VideoInfo/common/AdvisorContainer';
import AdvisorVideo from 'components/NewOneBoard/VideoInfo/common/WhiteboardVideo';
import { MAX_STUDENT_VIDEO_COUNT } from 'config';
import { useVideoListContext } from 'providers/VideoListProvider';
import { t } from 'utils/i18n';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
`;

const BottomContainer = styled.div`
  display: flex;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StagedVideoListLayout = styled.div`
  display: flex;
  min-height: 136px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(145, 158, 171, 0.16);
`;

export const SyncObserverV2 = ({ meetingId }) => {
  const { assignMultiUserRewards, updateVideoList } = useMeetingDispatch();
  const meetingState = useMeetingState();
  const { role, stagedAttendeeIds, attendeeId: selfAttendeeId, roomId, courseType, userName } = meetingState.context;
  const { muted, toggleMute } = useToggleLocalMute();
  const { service: whiteboardService } = useWhiteboard();
  const { scaleToFit } = useZoomControl();
  const { openNotification } = useNotification();
  const { roster } = useRosterState();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const attendees = Object.values(roster);
  const students = attendees.filter((attendee) => attendee.role === Roles.Student);
  const advisors = Object.values(roster).filter((attendee) => attendee.role === Roles.Advisor);
  const isAdvisorJoined = attendees.find((attendee) => attendee.role === Roles.Advisor);
  const { videoList: localVideoList } = useVideoListContext();
  const isEnableMask = userName === 'recordObserver' && courseType === ClassType.SyncStreaming;

  const studentsPrevious = usePrevious(students) || [];
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const [iframeGroup, setIframeGroup] = useState([]);
  useEffect(() => {
    if (isVideoEnabled) {
      toggleVideo();
    }

    if (!muted) {
      toggleMute();
    }
  }, []);

  const notificationStudentNumberChangeHandler = () => {
    const complementarySetResult = complementarySet(students, studentsPrevious);
    if (students.length > studentsPrevious.length) {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: t(NOTIFICATION_TYPE_I18N.ENTER),
          key: NOTIFICATION_TYPE_KEY.ENTER,
        })
      );
    } else {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: t(NOTIFICATION_TYPE_I18N.LEAVE),
          key: NOTIFICATION_TYPE_KEY.LEAVE,
        })
      );
    }
  };

  useEffect(() => {
    notificationStudentNumberChangeHandler();
  }, [students.length]);

  useEffect(() => {
    if (!whiteboardService) return;

    whiteboardService
      .setWritable(false)
      .then(function () { })
      .catch(function (err) {
        console.log('whiteboardServiceErr', err.message);
      });
  }, [whiteboardService]);

  useEffect(() => {
    if (!whiteboardService) return;

    const handler = () => {
      scaleToFit();
    };

    whiteboardService.addMagixEventListener('changeBookId', handler);
    whiteboardService.addMagixEventListener('closeViewMode', handler);

    return () => {
      whiteboardService.removeMagixEventListener('changeBookId', handler);
      whiteboardService.removeMagixEventListener('closeViewMode', handler);
    };
  }, [whiteboardService]);

  const { sharingAttendeeId } = useContentShareState();
  const contentSharingAttendeeId = useMemo(() => sharingAttendeeId?.split('#')[0] || '', [sharingAttendeeId]);
  const isShowContentShare = useMemo(
    () => contentSharingAttendeeId && contentSharingAttendeeId !== selfAttendeeId,
    [selfAttendeeId, contentSharingAttendeeId]
  );

  useEffect(() => {
    // get 獎盃紀錄
    (async () => {
      const multiRecord = await getMultiUserRecord({
        courseId: roomId,
      });
      const rewardsMap = {};

      if (!multiRecord) return;
      multiRecord.forEach((item) => (rewardsMap[item.userId] = item.summary?.trophy || 0));

      assignMultiUserRewards({ rewardsMap });
    })();
  }, []);

  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);

  const closeTeachingMaterialHandler = (material) => {
    switch (material.type) {
      case 'iframe':
        setIframeGroup((prev) => prev.filter((item) => item.sources !== material.sources));
        break;
      default:
        break;
    }
  };

  /**
   * 老師自己的輪播列表
   */
  const nextLocalVideoList = useMemo(() => {
    return localVideoList.filter((_tile, index) => index < MAX_STUDENT_VIDEO_COUNT);
  }, [localVideoList]);

  /**
   * 顧問在的時候在 broadcast 列表資訊
   */
  useEffect(() => {
    if (!nextLocalVideoList || !isAdvisorJoined) return;
    updateVideoList({ videoList: nextLocalVideoList });
  }, [isAdvisorJoined, nextLocalVideoList]);

  return (
    <>
      <MainLayoutV2
        main={
          <MainContainer>
            {/* 觀察者學生上台列表 */}
            {/* {stagedAttendeeIds.length > 0 && (
              <StagedVideoListLayout>
                <SyncStagedVideoList />
              </StagedVideoListLayout>
            )} */}

            <Whiteboard disableToolbox={false} userRole={role} toolBox={<ToolBox />} />
            {/* 全部的輪播列表 */}
            {courseType !== ClassType.SyncSingle && nextLocalVideoList.length > 0 && (
              <StagedVideoListLayout>
                <SyncVideoListV2
                  videoList={nextLocalVideoList}
                  courseType={courseType}
                  isEnableMask={isEnableMask}
                />
              </StagedVideoListLayout>
            )}
            {isShowContentShare && (
              <ContentShareContainer>
                <ContentShare />
              </ContentShareContainer>
            )}
            <TeachingMaterialContainer iframeGroup={iframeGroup} onClose={closeTeachingMaterialHandler} />
            <AdvisorContainer userType={'observer'} isStudentList={stagedAttendeeIds.length > 0}>
              {advisors.map((advisor) => (
                <AdvisorVideo
                  key={advisor.chimeAttendeeId}
                  userType={advisor.role}
                  tileId={attendeeIdToTileId[advisor.chimeAttendeeId]}
                  attendeeId={advisor.chimeAttendeeId}
                  advisorName={advisor.name}
                />
              ))}
            </AdvisorContainer>
          </MainContainer>
        }
        side={
          <RightContainer>
            <Observer isSettingModalOpen={isSettingModalOpen} />
          </RightContainer>
        }
        footer={
          <BottomContainer>
            {/* <Footer isSettingModalOpen={isSettingModalOpen} setIsSettingModalOpen={setIsSettingModalOpen} /> */}
          </BottomContainer>
        }
      />
    </>
  );
};

SyncObserverV2.propTypes = {
  meetingId: PropTypes.string,
};
