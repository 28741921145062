import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import {
  PreviewVideo as ChimePreviewVideo,
  CameraSelection as ChimeCameraSelection,
  Label as ChimeLabel,
  useVideoInputs,
  useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';
import { useMeetingDispatch, useMeetingState } from '../../index';
import Icon from '@onedesign/icon';
import { Box, CheckBox } from '@oneboard/ui-components';
import { Roles } from 'constants/index';
import { StyleDeviceNotFound, StylePreviewVideo } from './CameraDevices.style';
import { t } from 'utils/i18n';

const Label = styled(ChimeLabel)`
  display: block;
  color: #212b36;
`;

const StyledPreviewVideo = styled.div`
  border-radius: 16px;
  overflow: hidden;
  font-size: 0;
`;

const DeviceSelection = styled.select`
  display: flex;
  height: 40px;
  padding: 8px 8px 7px 14px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 75px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid rgba(145, 158, 171, 0.32);
  color: #919eab;
  width: 100%;
`;

const CheckBoxWrapper = styled.div`
  margin: 20px 0 20px;
`;

const videoInputConfig = {
  additionalDevices: true,
};

const StyledCheckBox = styled(CheckBox)`
  :checked:before {
    background-color: green;
  }
`;

const HintText = styled.div`
  color: #919eab;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  gap: 4px;
`;

export const isOptionActive = (meetingManagerDeviceId, currentDeviceId) => {
  if (currentDeviceId === 'none' && meetingManagerDeviceId === null) {
    return true;
  }
  return currentDeviceId === meetingManagerDeviceId;
};

export const CameraSelection = () => {
  const meetingManager = useMeetingManager();
  const { devices: inputDevices, selectedDevice } = useVideoInputs();

  async function selectVideoInput(deviceId) {
    let sourceId = deviceId;

    if (deviceId === 'none') {
      sourceId = undefined;
    }

    meetingManager.startVideoInputDevice(sourceId);
  }

  const devices = useMemo(() => {
    return [
      {
        label: t('meeting.components.cameraDevices.closed', '關閉'),
        deviceId: 'none',
        kind: 'videoinput',
      },
      ...inputDevices,
    ];
  }, [inputDevices]);

  const VideoInputControl = () => {
    const meetingManager = useMeetingManager();
    const { devices, selectedDevice } = useVideoInputs(videoInputConfig);

    const handleDeviceChange = (event) => {
      const selectedDeviceId = event.target.value;
      meetingManager.selectVideoInputDevice(selectedDeviceId);
    };

    return (
      <DeviceSelection onChange={handleDeviceChange} defaultValue={selectedDevice}  >
        {devices.map((device) => (
          <option
            key={device.deviceId}
            value={device.deviceId}
          >
            {device.label}
          </option>
        ))}
      </DeviceSelection>
    );
  };

  return (
    <div>
      <LabelWrapper>
        <Icon name='VideoSolid' size='sm' color={'#212B36'} />
        <Label>
          {t('meeting.components.cameraDevices.videoSource', '視訊來源')}
        </Label>
      </LabelWrapper>
      <VideoInputControl />
    </div>
  );
};

const DeviceNotFound = () => {
  return (
    <StyleDeviceNotFound>
      <div className='content'>
        <Box display='flex' justifyContent='center'>
          <Icon name='ExclamationCircleSolid' />
        </Box>
        <Box mt={1}>{t('meeting.components.cameraDevices.noCameraDetected', '沒有偵測到相機設備')}</Box>
      </div>
    </StyleDeviceNotFound>
  );
};

export const PreviewVideo = ({ label = true, role = Roles.Student }) => {
  const meetingManager = useMeetingManager();
  const { devices, selectedDevice, selectDeviceError } = useVideoInputs();
  const { toggleVideoIsMirroring } = useMeetingDispatch();
  const { context } = useMeetingState();
  const { videoIsMirroring } = context;

  const [isMirroring, setIsMirroring] = useState(videoIsMirroring);

  const IPAD_AND_IPHONE_TEXT = t(
    'meeting.components.cameraDevices.iPadAndIPhone',
    '建議使用最新版 Safari 瀏覽器，搭配平板或電腦，以獲得最佳上課體驗。'
  );
  const NOT_IPAD_AND_IPHONE_TEXT = t(
    'meeting.components.cameraDevices.otherDevices',
    '建議使用最新版 Chrome 瀏覽器，搭配平板或電腦，以獲得最佳上課體驗。'
  );
  const CHROME_UPDATE_TEXT = t(
    'meeting.components.cameraDevices.chromeUpdate',
    '請立即更新您的 Chrome 瀏覽器，以獲得最佳上課體驗。'
  );
  const SAFARI_UPDATE_TEXT = t(
    'meeting.components.cameraDevices.safariUpdate',
    '請立即更新您的 Safari 瀏覽器，以獲得最佳上課體驗。'
  );

  const videoFlipHandler = (event) => {
    setIsMirroring(event.target.checked);
    toggleVideoIsMirroring({ videoIsMirroring: event.target.checked });
  };

  function isiOSOriPadOS() {
    const userAgent = window.navigator.userAgent;
    return /iPad|iPhone|iPod/.test(userAgent);
  }

  function getBrowserInfo() {
    const ua = navigator.userAgent;
    let browserName = 'Unknown';
    let browserVersion = 'Unknown';
    let isAndroid = false;

    // 檢查是否為 Android 裝置
    if (/Android/i.test(ua)) {
      isAndroid = true;
    }
    // 檢查瀏覽器是否為 Chrome
    if (ua.indexOf('Chrome') > -1) {
      browserName = 'Chrome';
      const versionMatch = ua.match(/Chrome\/([\d.]+)/);
      browserVersion = versionMatch ? versionMatch[1] : 'Unknown';
    }
    // 檢查瀏覽器是否為 Safari
    else if (ua.indexOf('Safari') > -1 && ua.indexOf('Chrome') === -1) {
      browserName = 'Safari';
      const versionMatch = ua.match(/Version\/([\d.]+)/);
      browserVersion = versionMatch ? versionMatch[1] : 'Unknown';
    }

    return { browserName, browserVersion, isAndroid };
  }

  const browserInfo = getBrowserInfo();

  const browserUpdateText = useMemo(() => {
    if (browserInfo.browserName === 'Chrome') {
      if (
        (browserInfo.isAndroid && parseFloat(browserInfo.browserVersion) < 10) ||
        parseFloat(browserInfo.browserVersion) < 78
      ) {
        return CHROME_UPDATE_TEXT;
      }
    } else if (browserInfo.browserName === 'Safari' && parseFloat(browserInfo.browserVersion) < 13) {
      return SAFARI_UPDATE_TEXT;
    }
    return '';
  }, [browserInfo]);

  useEffect(() => {
    if (selectedDevice || devices.length === 0) return;
  }, [devices]);
  return (
    <StylePreviewVideo>
      {selectDeviceError || !selectedDevice ? (
        <DeviceNotFound />
      ) : (
        <div>
          <StyledPreviewVideo className={!isMirroring ? 'isFlip' : ''}>
            <ChimePreviewVideo />
          </StyledPreviewVideo>
          {label && (
            <>
              <CheckBoxWrapper>
                <StyledCheckBox className='mirrorCheckbox' checked={isMirroring} onChange={videoFlipHandler}>
                  {t('meeting.components.cameraDevices.mirrorCode', '鏡像模式')}
                </StyledCheckBox>
              </CheckBoxWrapper>
              {browserUpdateText ? (
                <HintText>{browserUpdateText}</HintText>
              ) : (
                <HintText>{isiOSOriPadOS() ? IPAD_AND_IPHONE_TEXT : NOT_IPAD_AND_IPHONE_TEXT}</HintText>
              )}
            </>
          )}
        </div>
      )}
    </StylePreviewVideo>
  );
};

export const CameraDevices = () => {
  return (
    <div>
      <CameraSelection />
      {/* <QualitySelection /> */}
      <PreviewVideo />
    </div>
  );
};
