import PropTypes from 'prop-types';
import { useQuery } from 'utils/hooks/useQuery';
import { useCourseInfo } from 'providers/CourseInfoProvider';
import { t } from 'utils/i18n';
import Books from './Books';
import ResourceBooks from './ResourceBooks';
import { fetchBooksHandler, fetchBook } from '../utils';
import { useResourceModal } from 'providers/ResourceModalProvider';
import { PRODUCT_TYPE } from 'constants/index';
import { Box } from '@oneboard/ui-components';
import { useBook } from '../hooks';

const Textbooks = () => {
  const query = useQuery();
  const { bookIdChangeHandler } = useBook();
  const { courseInfo } = useCourseInfo();
  const { currentBookId } = useResourceModal();
  const bookIds = query?.bookIds?.filter((id) => id !== '') || null;
  const isOnelink = query.productType === PRODUCT_TYPE.onelink;

  return (
    !bookIds || bookIds.length === 0 ? (
      <Box display='flex' justifyContent='center'>
        {t('containers.bookList.noTextbookAvailable', '尚無可使用的教材')}
      </Box>
    ) : isOnelink || !courseInfo ? (
      <Books
        books={bookIds}
        currentBookId={currentBookId}
        onBookIdChange={bookIdChangeHandler}
        fetchBooks={fetchBooksHandler}
      />
    ) : (
      <ResourceBooks
        books={bookIds}
        onBookIdChange={bookIdChangeHandler}
        currentBookId={currentBookId}
        fetchBook={fetchBook}
        courseInfo={courseInfo}
      />
    )
  );
};

Textbooks.propTypes = {
  onBookIdChange: PropTypes.func,
};

export default Textbooks;