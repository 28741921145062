import React, { useRef, useEffect, useMemo, useState } from 'react';
import Icon from '@onedesign/icon';
import lottie from 'lottie-web';
import { useMeetingState } from '@oneboard/meeting';
import { formatSeconds } from 'utils/time';
import { StyledClassroomPerformanceCard, StyledFireworks } from './ClassroomPerformanceCard.style';
import styled from 'styled-components';
import { SendButton } from 'components/NewOneBoard/common/MessageButton';
import { t } from 'utils/i18n';

const PerformanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  display: ${(props) => (props.isHidden ? 'none' : 'flex')};
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  border-radius: 16px;
`;

const TurnOffButton = styled(SendButton)`
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin-top: 32px;
  width: 50%;
  letter-spacing: 1px;
  z-index: 2;
`;

export const ClassroomPerformanceCard = ({ className }) => {
  const ref = useRef();

  const meetingState = useMeetingState();
  const { context } = meetingState;
  const { classroomPerformance, userId } = context;

  const currentClassroomPerformance = useMemo(() => {
    if (!classroomPerformance) return null;
    return classroomPerformance.find((item) => item.userId === userId);
  }, [classroomPerformance]);

  const [isPerformanceVisible, setPerformanceVisible] = useState(true);

  useEffect(() => {
    const animate = lottie.loadAnimation({
      container: ref.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/animations/fireworks.json',
    });

    return () => {
      animate.onComplete = null;
    };
  }, []);

  const handleTurnOffPerformanceCard = () => {
    setPerformanceVisible(false);
  };

  return (
    <PerformanceContainer isHidden={!isPerformanceVisible}>
      <Overlay />
      <StyledFireworks ref={ref} />
      <StyledClassroomPerformanceCard className={className} data-testid='ClassroomPerformanceCard'>
        <div className='card'>
          <div className='cover'>
            <div className='icon'>
              <img src={`/images/performance-icon-${currentClassroomPerformance.iconIndex}.svg`} alt='' />
            </div>
            <div className='name'>{currentClassroomPerformance.name}</div>
          </div>
          <div className='main'>
            <div className='title'>
              {t('components.classroomPerformanceCard.title', 'Hi~同學你今天表現很優秀喔！期待下次再見到你～')}
            </div>
            <div className='subtitle'>{t('components.classroomPerformanceCard.subtitle', '今日的課堂表現：')}</div>
            <div className='row'>
              <div className='title'>{t('components.classroomPerformanceCard.speakingTime', '發言時間')}</div>
              <div className='content'>
                <img src='/images/clock.svg' alt='' />
                <div className='text'>{formatSeconds(currentClassroomPerformance.speakerSecond)}</div>
              </div>
            </div>
            <div className='row'>
              <div className='title'>{t('components.classroomPerformanceCard.trophyCount', '獎盃數')}</div>
              <div className='content'>
                <Icon name='TrophySolid' size='sm' color='#F9C74F' />
                <div className='text'>× {currentClassroomPerformance.trophy}</div>
              </div>
            </div>
          </div>
        </div>
      </StyledClassroomPerformanceCard>
      <TurnOffButton onClick={handleTurnOffPerformanceCard}>
        {t('components.classroomPerformanceCard.turnOffDisplay', '關閉顯示')}
      </TurnOffButton>
    </PerformanceContainer>
  );
};
