import React, { useState, useRef, useMemo, useEffect } from 'react';
import MainLayout from 'layouts/MainLayoutV2';
import styled from 'styled-components';
import Advisor from 'components/NewOneBoard/VideoInfo/Advisor';
import Whiteboard from 'components/NewOneBoard/Whiteboard';
import ToolBox from 'components/NewOneBoard/ToolBox';
import Footer from 'components/NewOneBoard/Footer';
import {
  useRosterState,
  ContentShare,
  useContentShareState,
  useRemoteVideoTileState,
} from 'amazon-chime-sdk-component-library-react';
import { PrivateMessageList } from 'containers';
import { SyncMainBlock } from 'components';
import { ContentShareContainer } from './SyncSingle.style';
import { useMeetingState } from '@oneboard/meeting';
import { Box } from '@oneboard/ui-components';
import AdvisorContainer from 'components/NewOneBoard/VideoInfo/common/AdvisorContainer';
import OtherVideos from 'components/NewOneBoard/VideoInfo/common/WhiteboardVideo';
import { useRoom } from 'providers/RoomProvider';
import { InsertPictureModal } from 'components';
import { complementarySet } from 'utils/array';
import { usePrevious } from 'ahooks';
import { useNotification } from 'utils/hooks/useNotification';
import { Roles, NOTIFICATION_TYPE_I18N, NOTIFICATION_TYPE_KEY } from 'constants/index';
import RewardWrapper from 'components/NewOneBoard/Reward/RewardWrapper';
import { useViewMode } from 'utils/hooks/useViewMode';
import { useIframeBridge } from 'utils/hooks/useIframeBridge';
import { useInteractiveObjects } from 'utils/hooks/useInteractiveObjects';
import { useWhiteboard } from '@oneboard/whiteboard';
import { t } from 'utils/i18n';

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const BottomContainer = styled.div`
  display: flex;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SyncSingle = () => {
  const { roster } = useRosterState();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const attendees = Object.values(roster);
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);
  const { openNotification } = useNotification();
  const students = attendees.filter((attendee) => attendee.role === Roles.Student);
  const studentsPrevious = usePrevious(students) || [];
  const mainRef = useRef(null);
  const { sharingAttendeeId } = useContentShareState();
  const meetingState = useMeetingState();
  const { attendeeId: selfAttendeeId } = meetingState.context;
  const { insertPictureModalSwitch } = useRoom();
  const teacher = Object.values(roster).find((attendee) => attendee.role === 'teacher');
  const advisors = Object.values(roster).filter((attendee) => attendee.role === 'advisor');
  const student = useMemo(() => attendees.find((attendee) => attendee.role === Roles.Student), [attendees]);
  const { service: whiteboardService } = useWhiteboard();
  const currentViewMode = whiteboardService?.state?.broadcastState?.mode;

  let otherUsers = advisors.slice(1);
  useViewMode();
  useIframeBridge();
  useInteractiveObjects(currentViewMode);

  if (teacher) {
    otherUsers = [teacher, ...otherUsers];
  }

  const contentSharingAttendeeId = useMemo(() => sharingAttendeeId?.split('#')[0] || '', [sharingAttendeeId]);

  const isShowContentShare = useMemo(
    () => contentSharingAttendeeId && contentSharingAttendeeId !== selfAttendeeId,
    [selfAttendeeId, contentSharingAttendeeId]
  );

  const isSelfContentShare = useMemo(
    () => contentSharingAttendeeId && contentSharingAttendeeId === selfAttendeeId,
    [selfAttendeeId, contentSharingAttendeeId]
  );

  const notificationStudentNumberChangeHandler = () => {
    const complementarySetResult = complementarySet(students, studentsPrevious);
    if (students.length > studentsPrevious.length) {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: t(NOTIFICATION_TYPE_I18N.ENTER),
          key: NOTIFICATION_TYPE_KEY.ENTER,
        })
      );
    } else {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: t(NOTIFICATION_TYPE_I18N.LEAVE),
          key: NOTIFICATION_TYPE_KEY.LEAVE,
        })
      );
    }
  };

  useEffect(() => {
    notificationStudentNumberChangeHandler();
  }, [students.length]);

  return (
    <MainLayout
      main={
        <MainContainer>
          <SyncMainBlock ref={mainRef}>
            <PrivateMessageList filterRole={Roles.Advisor} containerRef={mainRef} />
            <Whiteboard toolBox={<ToolBox />} userRole={Roles.Advisor} />
            {isShowContentShare && (
              <ContentShareContainer>
                <ContentShare />
              </ContentShareContainer>
            )}
            {isSelfContentShare && (
              <Box width='100%' height='100%' position='absolute' zIndex='1000'>
                <ContentShare />
              </Box>
            )}
            {insertPictureModalSwitch && <InsertPictureModal />}
            <RewardWrapper student={student} />
          </SyncMainBlock>
          <AdvisorContainer>
            {otherUsers.map((user) => (
              <OtherVideos
                key={user.chimeAttendeeId}
                userType={user.role}
                tileId={attendeeIdToTileId[user.chimeAttendeeId]}
                attendeeId={user.chimeAttendeeId}
                advisorName={user.name}
              />
            ))}
          </AdvisorContainer>
        </MainContainer>
      }
      side={
        <RightContainer>
          <Advisor isSettingModalOpen={isSettingModalOpen} />
        </RightContainer>
      }
      footer={
        <BottomContainer>
          <Footer
            isSettingModalOpen={isSettingModalOpen}
            setIsSettingModalOpen={setIsSettingModalOpen}
            isTeacherHere={teacher !== undefined}
          />
        </BottomContainer>
      }
    ></MainLayout>
  );
};
