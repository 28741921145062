import PropTypes from 'prop-types';
import { StyledOptionView } from './QuizModal.style';
import { t } from 'utils/i18n';

const Options = ['A', 'B', 'C', 'D', 'E'];

export const OptionView = ({
  count,
  clickable,
  value,
  onChange = () => {},
}) => {
  const clickHandler = (event, index) => {
    event.stopPropagation();
    onChange(Options[index]);
  };

  const createOption = (count) => {
    return Options.slice(0, count).map((option, index) => (
      <div
        key={option}
        onClick={(event) => clickHandler(event, index)}
        onTouchEnd={(event) => clickHandler(event, index)}
        className={`option ${value === Options[index] ? 'active' : ''}`}
      >
        {option}
      </div>
    ));
  };

  return (
    <StyledOptionView clickable={clickable}>
      {!count
        ? t('containers.quizModal.optionView.preview', '選項預覽區')
        : createOption(count)}
    </StyledOptionView>
  );
};

OptionView.propTypes = {
  count: PropTypes.number,
  clickable: PropTypes.bool,
  activeIndex: PropTypes.number,
  onClick: PropTypes.func,
};
