import React, { useState, useEffect, useRef } from 'react';
import PageController from '@netless/page-controller';
import PreviewController from '@netless/preview-controller';
import styled from 'styled-components';
import { Roles } from 'constants/index';
import { Popover } from '@oneboard/ui-components';
import { useWhiteboard } from '@oneboard/whiteboard';
import { Tooltip } from 'antd';
import Icon from '@onedesign/icon';
import { t } from '@oneboard/meeting/src/utils/i18n';
import Tab from './Tab';
import StyledIcon from '../common/StyledIcon';
import { FileUploader } from 'components';
import { ReactComponent as FileImagePlus } from 'assets/newOneBoard/svg/file_image_plus.svg';
import { useGroupContext } from 'providers/GroupProvider';
import { useZoomControl } from 'utils/hooks/useZoomControl';
import { IframeBridge } from '@oneboard/iframe-bridge';

const WhiteHeaderContainer = styled.div`
  display: ${({ userRole }) => (userRole === 'student' || userRole === 'observer' ? 'none' : 'grid')};
  position: absolute;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 40px;
  padding: 0px 29px;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  background: #75bec2;
  grid-template-columns: minmax(0, 1fr) max-content;
  gap: 15px;
  border-radius: 16px 16px 0px 0px;
  .controller {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 0 0 auto;
    .otherTool {
      display: flex;
      gap: 4px;
    }
  }
`;

const InitScene = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  height: 100%;
  line-height: 24px;
  margin: 0;
  cursor: pointer;
  white-space: nowrap;
  padding: 0 12px;
  color: ${(props) => (props.isActive ? '#637381' : '#FFFFFF')};
  background-color: ${(props) => (props.isActive ? '#f9fafb' : 'rgba(255, 255, 255, 0.12)')};
  border-radius: 16px 16px 0px 0px;
  &:hover {
    background-color: ${(props) => (!props.isActive ? 'rgba(255, 255, 255, 0.48)' : '#f9fafb')};
    color: #637381;
  }
`;

const TabWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const TabContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  .tab {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: #fff;
  cursor: pointer;
`;

const TransformedIcon = styled(IconBox)`
  transform: rotate(-90deg);
  color: ${({ isPopoverOpen }) => isPopoverOpen && '#75bec2'};

  &:hover {
    color: #75bec2;
  }
`;

const DividingLine = styled.div`
  width: 1px;
  height: 32px;
  background-color: #4fa1a6;
`;

const TabController = styled.div`
  display: flex;
  border-radius: 8px;
  color: ${({ isPopoverOpen }) => isPopoverOpen && '#75bec2'};
  background-color: ${({ isPopoverOpen }) => isPopoverOpen && '#fff'};

  &:hover {
    background-color: #fff;
    color: #75bec2;
  }
`;

const ResourceWrapper = styled.div`
  background-color: #86c6c9;
  padding: 16px 8px;
  height: 180px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 3px;
  }
`;

const ResourceContent = styled.div`
  color: #fff;
  width: 217px;
  padding: 4px 8px;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #b7dddf;
    color: #637381;
  }
`;

export function WhiteboardHeader({ userRole }) {
  const { service: whiteboardService, addPage } = useWhiteboard();
  const [isShowPreviewMenu, setIsShowPreviewMenu] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const { sceneTabsData, setCurrentScene, removeSceneQueue, updateSceneMap } = useGroupContext();
  const { currentScene, queue, sceneMap } = sceneTabsData;
  const { scaleToFit } = useZoomControl();
  const tabContainerRef = useRef(null);
  const room = whiteboardService;
  const popoverContent = (
    <ResourceWrapper>
      {queue.map((bookId) => {
        const { label } = sceneMap[bookId];
        return (
          <ResourceContent key={bookId} onClick={() => onTabClickHandler(bookId)}>
            {label}
          </ResourceContent>
        );
      })}
    </ResourceWrapper>
  );

  const generateRootScene = () => {
    const isInitSceneExist = room.scenePathType('/init') !== 'none';
    const entireScenes = room.entireScenes();

    if (isInitSceneExist) {
      return 'init';
    }

    if ('/' in entireScenes) {
      return entireScenes['/'][0].name;
    }

    room.putScenes('/', [{ name: 'init' }], 0);
    return 'init';
  };

  const onTabClickHandler = (bookId) => {
    if (bookId.indexOf('iframe') > -1) {
      const { width, height, name } = sceneMap[bookId];
      const page = name === undefined ? '1' : name;
      whiteboardService.setScenePath(`/${bookId}/${page}`);
      setCurrentScene({ bookId });
      scaleToFit();
      let bridge = whiteboardService.getInvisiblePlugin(IframeBridge.kind);
      bridge.setAttributes({ width, height });
      bridge.scaleIframeToFit();
    }

    if (bookId.indexOf('interactive_learnings_') > -1) {
      const width = 1024;
      const height = 768;
      const page = '1';
      whiteboardService.setScenePath(`/${bookId}/${page}`);
      setCurrentScene({ bookId });
      scaleToFit();
      let bridge = whiteboardService.getInvisiblePlugin(IframeBridge.kind);
      bridge.setAttributes({ width, height });
      bridge.scaleIframeToFit();
    }
  };

  const removeScenesHandler = (event, bookId, index) => {
    event.stopPropagation();
    let nextBookId = '';
    let nextScenePath = '';

    if (queue.length === 1) {
      nextBookId = generateRootScene();
      nextScenePath = `/${nextBookId}`;
    } else if (index !== queue.length - 1) {
      nextBookId = queue[index + 1];
      const { name } = sceneMap[nextBookId];
      nextScenePath = `/${nextBookId}/${name}`;
    } else {
      nextBookId = queue[index - 1];
      const { name } = sceneMap[nextBookId];
      nextScenePath = `/${nextBookId}/${name}`;
    }
    room.setScenePath(nextScenePath);
    room.removeScenes(`/${bookId}`);
    removeSceneQueue({ bookId, nextBookId });
    scaleToFit();

    if (nextBookId.indexOf('iframe') > -1) {
      const { width, height } = sceneMap[nextBookId];
      let bridge = room.getInvisiblePlugin(IframeBridge.kind);
      bridge.setAttributes({ width, height });
      bridge.scaleIframeToFit();
    }
  };

  const pagePreviewHandler = (state) => {
    setIsShowPreviewMenu(state);
  };

  const updateBackgroundImage = ({ fileName, url }) => {
    const sceneState = whiteboardService.state.sceneState;
    const img = new Image();
    img.onload = function () {
      whiteboardService.putScenes(sceneState.contextPath, [
        {
          name: fileName,
          ppt: {
            src: url,
            width: this.width,
            height: this.height,
          },
        },
      ]);
      const targetIndex = sceneState.scenes.length;
      whiteboardService.setSceneIndex(targetIndex);
      handleSceneUpload();
    };
    img.src = url;
  };

  const handleSceneUpload = () => {
    pagePreviewHandler(true);
    setTimeout(() => {
      const element = document.querySelector('nav.bm-item-list > .bm-item');
      element.scrollTop = element.scrollHeight;
    }, 100);
  };

  const switchToMainScene = () => {
    whiteboardService.setScenePath('/init');
    setCurrentScene({ bookId: 'init' });
    scaleToFit();
  };

  useEffect(() => {
    const handler = (state) => {
      if (!state?.sceneState) return;
      const bookId = state.sceneState.contextPath.split('/')[1];
      if (!bookId) return;
      const { index, sceneName } = state.sceneState;
      updateSceneMap({ bookId, index, name: sceneName });
    };
    room.callbacks.on('onRoomStateChanged', handler);
    return () => room.callbacks.off('onRoomStateChanged', handler);
  }, [room]);

  useEffect(() => {
    const handleScroll = (event) => {
      if (tabContainerRef.current) {
        const delta = event.deltaY;
        tabContainerRef.current.scrollLeft += delta;
      }
    };

    if (tabContainerRef.current) {
      tabContainerRef.current.addEventListener('wheel', handleScroll);
    }

    return () => {
      if (tabContainerRef.current) {
        tabContainerRef.current.removeEventListener('wheel', handleScroll);
      }
    };
  }, []);
  return (
    <>
      <WhiteHeaderContainer userRole={userRole}>
        {userRole !== Roles.Student && (
          <>
            <TabWrapper>
              <InitScene onClick={switchToMainScene} isActive={currentScene === 'init'}>
                {t('components.newOneBoard.whiteboardHeader.home', '首頁')}
              </InitScene>
              <TabContainer ref={tabContainerRef}>
                {queue.map((bookId, index) => {
                  const { label } = sceneMap[bookId];
                  return (
                    <Tab
                      key={bookId}
                      isActive={bookId === currentScene}
                      onClick={() => onTabClickHandler(bookId)}
                      onRemove={(event) => removeScenesHandler(event, bookId, index)}
                    >
                      {label}
                    </Tab>
                  );
                })}
              </TabContainer>
            </TabWrapper>
            <div className='controller'>
              {queue.length > 0 && (
                <Popover
                  placement='topRight'
                  trigger='click'
                  arrow={false}
                  content={popoverContent}
                  visible={isPopoverVisible}
                  onVisibleChange={(visible) => setIsPopoverVisible(visible)}
                >
                  <TabController isPopoverOpen={isPopoverVisible}>
                    <TransformedIcon isPopoverOpen={isPopoverVisible}>
                      <Icon name='CaretLeftSolid' size='xxs' />
                    </TransformedIcon>
                  </TabController>
                </Popover>
              )}
              <PageController room={whiteboardService} />
              <DividingLine />
              <div className='otherTool'>
                <IconBox onClick={addPage}>
                  <Icon name='FileAddSolid' size='md' />
                </IconBox>
                <FileUploader onUploaded={updateBackgroundImage}>
                  <Tooltip
                    placement='top'
                    title={t('components.newOneBoard.whiteboardHeader.uploadPhoto', '上傳相片至教材')}
                  >
                    <IconBox>
                      <StyledIcon as={FileImagePlus} size='24px' />
                    </IconBox>
                  </Tooltip>
                </FileUploader>
                <IconBox onClick={() => pagePreviewHandler(true)}>
                  <Icon name='ThSolid' size='md' />
                </IconBox>
              </div>
            </div>
          </>
        )}
      </WhiteHeaderContainer>
      {userRole !== Roles.Student && (
        <PreviewController
          room={whiteboardService}
          isVisible={isShowPreviewMenu}
          handlePreviewState={pagePreviewHandler}
        />
      )}
    </>
  );
}
