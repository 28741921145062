import { useContentShareControls, useContentShareState } from 'amazon-chime-sdk-component-library-react';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import Icon from '@onedesign/icon';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import { t } from 'utils/i18n';

export const ShareButton = ({ isTeacherHere }) => {
  const { toggleContentShare } = useContentShareControls();
  const { isLocalUserSharing } = useContentShareState();
  const { isFooterOpen } = useFooterStatus();
  const color = isTeacherHere ? '#919EAB' : isLocalUserSharing ? '#FF9E5F' : '#80531E';

  return (
    <ToolButton
      isTeacherHere={isTeacherHere}
      icon={<Icon name={isLocalUserSharing ? 'CloseSquareSolid' : 'ArrowSquareUpSolid'} size='md' color={color} />}
      onClick={toggleContentShare}
    >
      {isFooterOpen && t('components.newOneBoard.footer.shareButton.screenShare', '分享螢幕')}
    </ToolButton>
  );
};
