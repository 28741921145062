import React, { useState, useEffect } from 'react';
import { useWhiteboard } from '@oneboard/whiteboard';
import { useZoomControl } from 'utils/hooks/useZoomControl';
import { useResourceModal } from 'providers/ResourceModalProvider';
import { useGroupContext } from 'providers/GroupProvider';
import Books from './Books';
import { getBookIds } from 'services/books';
import { useMaterialButton } from 'components/NewOneBoard/Footer/component/MaterialButton/MaterialButton';
import { fetchBooksHandler } from '../utils';

const createScenes = (bookId, pages) => {
  return pages.map((page, index) => ({
    name: '' + (index + 1),
    ppt: {
      src: `${process.env.REACT_APP_BOOKS_IMAGE_URL}/${bookId}/${page.name}`,
      width: page.width,
      height: page.height,
    },
  }));
};

const getBookImageJsonById = async (bookId) => {
  return fetch(`${process.env.REACT_APP_BOOKS_IMAGE_URL}/${bookId}/book.json`).then((res) => res.json());
};

const ExampleBooks = () => {
  const { service: whiteboardService } = useWhiteboard();
  const { scaleToFit } = useZoomControl();
  const { closeModal: closeMaterialButtonModal } = useMaterialButton();
  const { currentBookId, setCurrentBook } = useResourceModal();
  const { addSceneQueue } = useGroupContext();
  const [exampleBookIds, setExampleBookIds] = useState([]);

  const exampleBookIdChangeHandler = async ({ bookInfo }) => {
    const { bookId, display_name: label } = bookInfo;
    const { pageInfos } = await getBookImageJsonById(bookId);
    whiteboardService.putScenes(`/${bookId}`, createScenes(bookId, pageInfos));
    whiteboardService.setScenePath(`/${bookId}/1`);
    setCurrentBook(bookId);
    scaleToFit();
    whiteboardService.dispatchMagixEvent('changeBookId');
    addSceneQueue({ bookId, label, name: '1' });
    closeMaterialButtonModal();
  };

  useEffect(() => {
    const getExampleBookIds = async () => {
      try {
        const res = await getBookIds();
        if (res && res.length > 0) {
          const nextBookIds = res.map((item) => item.bookId);
          setExampleBookIds(nextBookIds);
        }
      } catch (error) {
        console.log('error:', error.message);
      }
    };

    getExampleBookIds();
  }, []);

  return (
    <Books
      books={exampleBookIds}
      currentBookId={currentBookId}
      onBookIdChange={exampleBookIdChangeHandler}
      fetchBooks={fetchBooksHandler}
    />
  );
};

export default ExampleBooks;