/**
 * variable & Object key 大寫
 */


export const ClassType = {
  Single: 'single',
  Group: 'group',
  Multiple: 'multiple',
  SyncSingle: 'sync-single',
  SyncMultiple: 'sync-multiple',
  SyncStreaming: 'sync-streaming'
};

export const ClassStyle = {
  Normal: 'normal',
  Trial: 'trial'
};

export const Roles = {
  Teacher: 'teacher',
  Student: 'student',
  Advisor: 'advisor',
  Observer: 'observer'
};


export const NOTIFICATION_TYPE_KEY = {
  ENTER: 'ENTER',
  LEAVE: 'LEAVE',
  RAISED_HAND: 'RAISED_HAND',
  NO_FOCUS: 'NO_FOCUS',
  HAND_POSE_RAISED_HAND: 'HAND_POSE_RAISED_HAND',
};

export const NOTIFICATION_TYPE = {
  [NOTIFICATION_TYPE_KEY.ENTER]: '進入教室',
  [NOTIFICATION_TYPE_KEY.LEAVE]: '離開教室',
  [NOTIFICATION_TYPE_KEY.RAISED_HAND]: '舉手',
  [NOTIFICATION_TYPE_KEY.NO_FOCUS]: '不專心',
  [NOTIFICATION_TYPE_KEY.HAND_POSE_RAISED_HAND]: '使用舉手手勢'
};

export const NOTIFICATION_TYPE_I18N = {
  [NOTIFICATION_TYPE_KEY.ENTER]: 'containers.notification.enter',
  [NOTIFICATION_TYPE_KEY.LEAVE]: 'containers.notification.leave',
  [NOTIFICATION_TYPE_KEY.RAISED_HAND]: 'containers.notification.raisedHand',
  [NOTIFICATION_TYPE_KEY.NO_FOCUS]: 'containers.notification.noFocus',
  [NOTIFICATION_TYPE_KEY.HAND_POSE_RAISED_HAND]: 'containers.notification.handPoseRaisedHand'
};

export const PRODUCT_TYPE = {
  onelink: 'onelink',
  liveoneclass: 'liveoneclass',
  mmsTeacher: 'mmsTeacher'
};

export const API_STATUS = {
  SUCCESS: 'success',
  FAILURE: 'failure'
};

// 學制
export const EDUCATION_LEVEL = {
  ELEMENTARY: 'E',
  JUNIOR: 'J',
  SENIOR: 'H',
  OTHER: 'other'
};

export const DEVICE_PERMISSION_STATE = {
  UNTRIGGERED: 'UNTRIGGERED',
  IN_PROGRESS: 'IN_PROGRESS',
  GRANTED: 'GRANTED',
  DENIED: 'DENIED',
};

export const FCM_TOPIC = {
  ONEBOARD_COURSE_FEEDBACK: 'oneboardCourseFeedback',
};