import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'utils/hooks/useQuery';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Loading, Box, Modal, Button } from '@oneboard/ui-components';
import { useWhiteboard } from '@oneboard/whiteboard';
import { RoomWhiteboard } from 'white-react-sdk';
import ToolBox from '@netless/tool-box';
import RedoUndo from '@netless/redo-undo';
import ZoomController from '@netless/zoom-controller';
import { Roles } from 'constants/index';
import { useMeetingManager, useRemoteVideoTileState } from 'amazon-chime-sdk-component-library-react';
import { useMeetingState } from '@oneboard/meeting';
import { useTimeout } from 'ahooks';
import { LoadingOutlined } from '@ant-design/icons';
import ToolModal from 'components/NewOneBoard/common/ToolModal';
import { SendButton } from 'components/NewOneBoard/common/MessageButton';
import WhiteboardHeader from 'components/NewOneBoard/WhiteboardHeader';
import { useStreaming } from 'providers/StreamingProvider';
import BroadcastButton from './BroadcastButton';
import { t } from 'utils/i18n';

const StudentDisabledToolIndexes = [6, 9];
const TeacherDisabledToolIndexes = [9];

const StyledSyncWhiteboard = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  .header {
    height: 53px;
  }
  .loadingWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  iframe#IframeBridge {
    z-index: -1;
  }

  &.clicker {
    iframe#IframeBridge {
      z-index: 0;
    }
  }
`;

const WhiteboardWrapper = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  border-radius: ${({ userRole }) => (userRole === 'student' || userRole === 'observer' ? '16px' : 'unset')};
  display: flex;
  justify-content: center;
`;

const StyledToolBox = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
`;

const ButtonContainer = styled(Box)`
  display: flex;
  height: 68px;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;
  align-items: center;
`;

const ModalText = styled.p`
  color: #637381;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding-left: 8px;
  align-self: center;
  margin: 0;
`;

const TextContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px 0;
`;

const ReviewImageButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50px;
  display: flex;
  padding: 0.375rem 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid #02a4ea;
  background-color: #fff;
  color: #02a4ea;
  font-weight: 600;
  cursor: pointer;
  z-index: 5;

  &:hover {
    background-color: #02a4ea;
    color: #fff;
  }
`;

const LiveText = styled.div`
  position: absolute;
  right: 10px;
  top: 50px;
  background-color: #ff4842;
  color: #fff;
  padding: 0rem 0.25rem;
  border-radius: 0.25rem;
  font-size: 14px;
  font-weight: 600;
  z-index: 5;
`;

export const Whiteboard = ({
  toolBox,
  disableToolbox,
  children,
  userRole,
  receivedImages,
  handleReceivedImagesModal,
}) => {
  const { meetingId, breakoutId } = useParams();
  const query = useQuery();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const { service: whiteboardService, loading, joinWhiteboard, whiteboardError, whiteboardPhase } = useWhiteboard();
  const { isBroadcasting } = useStreaming();
  const meetingManager = useMeetingManager();
  const { context } = useMeetingState();
  const { attendeeId: selfAttendeeId } = context;
  const role = query.role;
  const [errorCountdown, setErrorCountdown] = useState(null);

  const joinWhiteboardWithRetry = async () => {
    try {
      await joinWhiteboard({
        meetingId: meetingId,
        role: query.role,
        userName: query.userName,
        userId: query.userId,
      });
    } catch (error) {
      console.log('joinWhiteboardWithRetry error:', error);
      setTimeout(() => {
        joinWhiteboardWithRetry();
      }, 1000);
    }
  };

  useEffect(() => {
    if (role === Roles.Observer) {
      (async () => {
        const meetingRoomId = breakoutId ? breakoutId : meetingId;
        await joinWhiteboard({
          meetingId: meetingRoomId,
          role: query.role,
          userName: query.userName,
          userId: query.userId,
        });
      })();
    }
  }, []);

  useEffect(() => {
    if (!whiteboardService || !meetingManager) return;
    whiteboardService.plugins.setPluginContext('controllableVideo', {
      meetingManager,
      identity: role === Roles.Teacher ? 'host' : 'guest',
      attendeeIdToTileId: attendeeIdToTileId ? attendeeIdToTileId : {},
      selfAttendeeId,
    });
  }, [whiteboardService, meetingManager, role, attendeeIdToTileId, selfAttendeeId]);

  const toolboxRef = useRef();

  const hideTools = (disabledIndex) => {
    const toolboxCell = toolboxRef.current.querySelectorAll('.tool-box-cell-box-left');
    if (toolboxCell.length > 0) {
      toolboxCell.forEach((tool, index) => {
        if (disabledIndex.includes(index)) {
          tool.style.display = 'none';
        }
      });
    }
  };

  const hideStudentTools = (disableToolbox, disabledIndex) => {
    if (disableToolbox) {
      whiteboardService.disableDeviceInputs = true;
    } else {
      whiteboardService.disableDeviceInputs = false;
      hideTools(disabledIndex);
    }
  };

  useEffect(() => {
    if (!whiteboardService || !toolboxRef.current) return;
    if (query.role === Roles.Student) {
      hideStudentTools(disableToolbox, StudentDisabledToolIndexes);
    } else {
      hideTools(TeacherDisabledToolIndexes);
    }
  }, [disableToolbox, whiteboardService, toolboxRef.current]);

  useEffect(() => {
    if (!whiteboardService) return;
    if (query.role === Roles.Student) {
      whiteboardService.disableCameraTransform = true;
    }
  }, [whiteboardService]);

  useTimeout(() => {
    Sentry.captureMessage('ObserverRecord Reload (useTimeout):' + whiteboardError);
    window.location.reload();
  }, errorCountdown);

  useEffect(() => {
    if (query.role === Roles.Observer && whiteboardError !== null) {
      setErrorCountdown(1000 * 15);
    }
  }, [whiteboardError]);

  // oneBook 教材 click 層級
  useEffect(() => {
    if (!whiteboardService) return;
    const syncWhiteboardDom = document.getElementById('SyncWhiteboard');
    whiteboardService.callbacks.on('onRoomStateChanged', function (state) {
      if (state.memberState) {
        if (state.memberState.currentApplianceName === 'clicker') {
          syncWhiteboardDom?.classList?.add('clicker');
        } else {
          syncWhiteboardDom?.classList?.remove('clicker');
        }
      }
    });
  }, [whiteboardService]);

  useEffect(() => {
    if (whiteboardError !== null) {
      Sentry.captureMessage('whiteboardError Messages:' + whiteboardError);
    }
  }, [whiteboardError]);

  //禁用學生右鍵
  useEffect(() => {
    if (query.role === Roles.Student) {
      const disableRightClick = (e) => {
        e.preventDefault();
      };
      document.addEventListener('contextmenu', disableRightClick);
      return () => {
        document.removeEventListener('contextmenu', disableRightClick);
      };
    }
  }, [query.role]);

  return (
    <StyledSyncWhiteboard id='SyncWhiteboard' data-testid='SyncWhiteboard'>
      {(loading || whiteboardPhase === 'disconnected') && userRole !== Roles.Observer ? (
        <WhiteboardWrapper userRole={userRole}>
          {whiteboardService && whiteboardPhase === 'disconnected' && (
            <div className='loadingWrap'>
              <Loading />
              <button onClick={joinWhiteboardWithRetry}>
                {t('components.newOneBoard.whiteboard.reconnect', '重新連線')}
              </button>
            </div>
          )}
          <ToolModal
            active={true}
            showCloseButton={false}
            title={t('components.newOneBoard.whiteboard.warning', '警告')}
            showBottomContainer={true}
            height='260px'
            footerHeight='69px'
            justifyContent={'flex-start'}
            footerContent={
              <ButtonContainer>
                <SendButton onClick={joinWhiteboardWithRetry}>
                  {whiteboardPhase !== 'disconnected'
                    ? <LoadingOutlined />
                    : t('components.newOneBoard.whiteboard.reconnect', '重新連線')
                  }
                </SendButton>
              </ButtonContainer>
            }
          >
            <TextContainer>
              <ModalText>
                {t(
                  'components.newOneBoard.whiteboard.connectionError',
                  '白板連線異常，請點擊按鈕重新連線。'
                )}
              </ModalText>
            </TextContainer>
          </ToolModal>
        </WhiteboardWrapper>
      ) : (
        <>
          {whiteboardService && query.role !== Roles.Observer && (
            <>
              {isBroadcasting && <LiveText>
                {t('components.newOneBoard.whiteboard.live', '直播中')}
              </LiveText>}
              {role === Roles.Teacher && receivedImages?.length > 0 && (
                <ReviewImageButton onClick={handleReceivedImagesModal}>
                  {t('components.newOneBoard.whiteboard.reviewImage', '審核圖片')}
                </ReviewImageButton>
              )}
              <StyledToolBox ref={toolboxRef} show={!disableToolbox}>
                <ToolBox room={whiteboardService} customerComponent={[toolBox]} />
              </StyledToolBox>
              <Box display='flex' position='absolute' zIndex={5} bottom={2} right={2}>
                {query.role === Roles.Teacher && <BroadcastButton />}
                {query.role !== Roles.Student && <ZoomController room={whiteboardService} />}
                {query.role !== Roles.Student && (
                  <Box ml={2}>
                    <RedoUndo room={whiteboardService} />
                  </Box>
                )}
              </Box>
              {children}
            </>
          )}
          {whiteboardService && <WhiteboardHeader room={whiteboardService} userRole={userRole} />}

          <WhiteboardWrapper userRole={userRole}>
            {whiteboardService && whiteboardPhase === 'connected' && (
              // 扣掉右邊的工具列寬度
              <RoomWhiteboard style={{ width: 'calc(100% - 90px)', height: '100%' }} room={whiteboardService} />
            )}
          </WhiteboardWrapper>
          {whiteboardError && (
            <Modal header={t('components.newOneBoard.whiteboard.warning', '警告')} closeIcon={false}>
              {t(
                'components.newOneBoard.whiteboard.refreshWarning',
                '連結白板失敗，請點選「重新整理」再次進入會議室'
              )}
              <br />
              {t('components.newOneBoard.whiteboard.errorMessage', '錯誤訊息：')}{whiteboardError}
              <Box display='flex' justifyContent='flex-end' mt={5}>
                <Button onClick={() => window.location.reload()}>
                  {t('components.newOneBoard.whiteboard.reload', '重新整理')}
                </Button>
              </Box>
            </Modal>
          )}
        </>
      )}
    </StyledSyncWhiteboard>
  );
};

Whiteboard.propTypes = {
  toolBox: PropTypes.node,
  children: PropTypes.node,
  disableToolbox: PropTypes.bool,
};
