import {
  createContext,
  useContext,
  useState,
  useMemo
} from 'react';
import PropTypes from 'prop-types';

const ResourceModalContext = createContext(null);

const ResourceModalProvider = ({ children }) => {
  const [resourceModalSwitch, setResourceModalSwitch] = useState(false);
  const [seriesData, setSeriesData] = useState();
  const [currentBookId, setCurrentBook] = useState();

  const toggleResourceModalSwitch = () => setResourceModalSwitch(prev => !prev);


  const value = useMemo(() => ({
    seriesData,
    setSeriesData,
    currentBookId,
    setCurrentBook,
    resourceModalSwitch,
    toggleResourceModalSwitch
  }),
    [
      seriesData,
      currentBookId,
      resourceModalSwitch,
      toggleResourceModalSwitch
    ]
  );

  return (
    <ResourceModalContext.Provider value={value}>
      {children}
    </ResourceModalContext.Provider>
  );
};

ResourceModalProvider.propTypes = {
  children: PropTypes.node,
};

const useResourceModal = () => {
  const context = useContext(ResourceModalContext);
  if (!context) throw new Error('useResourceModal must be used within a ResourceModalProvider');

  return context;
};

export { ResourceModalProvider, useResourceModal };