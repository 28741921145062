import { useGroupContext } from 'providers/GroupProvider';
import Icon from '@onedesign/icon';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import { t } from 'utils/i18n';

export const TakeTurnsButton = () => {
  const { toggleCarouselPanel } = useGroupContext();
  const { isFooterOpen } = useFooterStatus();

  return (
    <ToolButton
      icon={<Icon name='UserRotationSolid' size='md' color={'#FF8E8E'} />}
      onClick={toggleCarouselPanel}
    >
      {isFooterOpen && t('components.newOneBoard.footer.takeTurnsButton.takeTurns', '輪流上台')}
    </ToolButton>
  );
};
