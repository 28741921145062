import React from 'react';
import { Box } from '@oneboard/ui-components';
import { LoadingOutlined } from '@ant-design/icons';
import { useResource } from 'utils/hooks/useResource';
import { useResourceModal } from 'providers/ResourceModalProvider';
import { t } from 'utils/i18n';
import Books from './Books';
import { fetchBooksHandler } from '../utils';
import { useBook } from '../hooks';

const EduResources = () => {
  const { bookIdChangeHandler } = useBook();
  const { currentBookId } = useResourceModal();
  const { resourceIds, errorMessage, loading } = useResource();

  if (loading) {
    return (
      <Box display='flex' justifyContent='center'>
        <LoadingOutlined style={{ color: '#A1A4B1' }} />
      </Box>
    );
  }

  return (
    <>
      {errorMessage && (
        <Box display='flex' justifyContent='center'>
          {errorMessage}
        </Box>
      )}
      {!errorMessage && (!resourceIds || resourceIds.length === 0) && (
        <Box display='flex' justifyContent='center'>
          {t('containers.bookList.noTextbookAvailable', '尚無可使用的教材')}
        </Box>
      )}
      {resourceIds && resourceIds.length !== 0 && (
        <Books
          books={resourceIds}
          currentBookId={currentBookId}
          onBookIdChange={bookIdChangeHandler}
          fetchBooks={fetchBooksHandler}
        />
      )}
    </>
  );
};

export default EduResources;