import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  useLocalVideo,
  useRosterState,
  useAudioVideo,
  useContentShareState,
  ContentShare,
} from 'amazon-chime-sdk-component-library-react';
import { useQuery } from 'utils/hooks/useQuery';
import {
  useMeetingDispatch,
  useMeetingState,
  MeetingStates,
  SettingPopoverButton,
  MeetingRoles
} from '@oneboard/meeting';
import {
  TeachingMaterialContainer,
  SyncWhiteboard,
  SyncToolBox,
  VideoListCarouselPanel,
  WorldWallModal,
  BreakoutRoomIntro,
  BreakoutRoomContainer,
  BreakoutRoomResult,
  SceneTab,
  QuizModal,
  SyncVideoList,
  RecordButton,
  RightContainer,
  StudentInfoBox,
  ClassroomPerformanceModal,
  PrivateMessageList
} from 'containers';
import UsersModalMap from 'containers/UsersModal';
import { BreakoutRoomNotification, TeacherSubRoomNotification, BreakoutRoomResultNotification } from 'containers/BreakoutRoomNotification';
import { MainLayout } from 'layouts';
import MainPageHeader from './Header';
import { RaiseHandControl, NetworkNotification, ScenesImageUploadButton, InsertPictureModal, ClassroomPerformanceButton, SyncMainBlock, ResourceModal } from 'components';
import { Roles, NOTIFICATION_TYPE, NOTIFICATION_TYPE_KEY } from 'constants/index';
import { useGroupContext } from 'providers/GroupProvider';
import { useVideoListContext } from 'providers/VideoListProvider';
import { useNotification } from 'utils/hooks/useNotification';
import { useViewMode } from 'utils/hooks/useViewMode';
import { useIframeBridge } from 'utils/hooks/useIframeBridge';
import { useInteractiveObjects } from 'utils/hooks/useInteractiveObjects';
import { usePrevious, useRequest, useSessionStorageState } from 'ahooks';
import { complementarySet } from 'utils/array';
import { Box, Modal, FullButton, Popover } from '@oneboard/ui-components';
import Icon from '@onedesign/icon';
import PageController from '@netless/page-controller';
import PreviewController from '@netless/preview-controller';
import { useWhiteboard } from '@oneboard/whiteboard';
import { useBreakoutMeeting } from 'providers/BreakoutMeetingProvider';
import { useNetworkContext } from 'providers/NetworkProvider';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import { MAX_STUDENT_VIDEO_COUNT } from 'config';
import { useQuizService } from 'utils/hooks/useQuiz';
import { useRealtimeActions } from 'utils/hooks/useRealtimeActions';
import { useStudentInfoContext } from 'providers/StudentInfoProvider';
import { useRoom } from 'providers/RoomProvider';
import {
  StyledSyncMultiple,
  StyleUsersModalLayout,
  StyledOtherButton,
  OtherOption,
  MuteOption,
  IconBox,
  Sidebar,
  NotificationLayout,
  ContentShareContainer,
  StyledBroadcastModal,
  StyledSyncVideoList
} from './SyncMultiple.style';
import { t } from 'utils/i18n';

const MuteAllContent = ({ muteDefaultControl }) => {
  const {
    usersMuteState,
    usersMuteHandler,
  } = useGroupContext();
  const { muteAllAttendees, unMuteAllAttendees } = useMeetingDispatch();
  const unMuteAllHandler = () => {
    unMuteAllAttendees();
    muteDefaultControl(false);

    let usersKeys = Object.keys(usersMuteState);
    let newUsersState = {};
    usersKeys.map(user => newUsersState[user] = false);
    usersMuteHandler(newUsersState);
  };

  const muteAllHandler = () => {
    muteAllAttendees();
    muteDefaultControl(true);

    let usersKeys = Object.keys(usersMuteState);
    let newUsersState = {};
    usersKeys.map(user => newUsersState[user] = true);
    usersMuteHandler(newUsersState);
  };

  return <MuteOption>
    <div className="optionList" onClick={unMuteAllHandler}>
      <div className="listIcon">
        <Icon name="MicrophoneAltSolid" />
      </div>
      <div className="listContent">
        {t('mainPage.teacher.syncMultiple.turnOnAllMicrophone', '開啟全體麥克風')}
      </div>
    </div>
    <div className="optionList" onClick={muteAllHandler}>
      <div className="listIcon">
        <Icon name="MicrophoneAltSlashSolid" />
      </div>
      <div className="listContent">
        {t('mainPage.teacher.syncMultiple.turnOffAllMicrophone', '關閉全體麥克風')}
      </div>
    </div>
  </MuteOption>;
};

MuteAllContent.propTypes = {
  muteDefaultControl: PropTypes.func
};

const MuteAll = ({ muteDefault, muteDefaultControl }) => {
  return <Popover
    content={<MuteAllContent muteDefaultControl={muteDefaultControl} />}
    trigger="click"
    placement="topLeft">
    {muteDefault ? <Icon name="UserVolumeMuteSolid" size='sm' /> : <Icon name="UserVolumeSolid" size='sm' />}
  </Popover>;
};

MuteAll.propTypes = {
  muteDefault: PropTypes.bool,
  muteDefaultControl: PropTypes.func
};

const OtherButton = () => {
  const { toggleCarouselPanel } = useGroupContext();

  return <StyledOtherButton>
    <Popover
      content={
        <OtherOption>
          <div className="otherList" onClick={toggleCarouselPanel}>
            <div className="listIcon">
              <Icon name="UserRotationSolid" size='sm' />
            </div>
            <div className="listContent">
              {t('mainPage.teacher.syncMultiple.takeTurns', '輪流上台')}
            </div>
          </div>
        </OtherOption>
      }
      trigger="click"
    >
      <Box className="controlButton">
        <Icon name="EllipsisVMediumSolid" size='xs' />
      </Box>
    </Popover>
  </ StyledOtherButton>;
};

export const SyncMultiple = () => {
  const { meetingId, breakoutId } = useParams();
  const query = useQuery();
  const audioVideo = useAudioVideo();
  const meetingState = useMeetingState();
  const { stagedAttendeeIds, callTeacherGroups, attendeeId: selfAttendeeId, toolboxAuthAttendeeIds } = meetingState.context;
  const {
    onstageFullModalState,
    toggleStageFullModal,
    usersModalState,
    toggleUsersModal,
    carouselPanelState,
    toggleCarouselPanel,
    usersMuteHandler,
    removeUsersMute,
    worldWallModalState,
    toggleWorldWallModal,
    breakoutRoomSidebarState,
    BreakoutRoomState,
    toggleBreakoutRoomSidebar,
    breakoutRoomStep,
    changeBreakoutRoomStep,
    breakoutRoomEndNotice,
    closeBreakoutRoomEndNotice,
    toggleBreakoutRoomIsLeaving,
    breakoutRoomIntroState,
    breakoutRoomIntroActions,
    setBreakoutRoomResultData,
    breakoutRoomResultState,
    breakoutRoomResultActions,
    resultReviewState,
    resultReviewActions,
    breakoutRoomIsEndState,
    setBreakoutRoomIsEndState,
    broadcastModalState,
    setBroadcastModalActions
  } = useGroupContext();
  const { videoListCarouselStart, videoListCarouselStop, videoList: localVideoList } = useVideoListContext();
  const { unstageAttendee, unMuteAttendee, muteAttendee, breakoutMeetingLeave, removeAllCallTeacherGroups, updateVideoList, updateStaged, enableToolbox } = useMeetingDispatch();
  const { isBreakoutRoom, stopBreakout, getBreakoutLatest, goToBreakoutMeeting, broadcastMessageToAll } = useBreakoutMeeting();

  const [iframeGroup, setIframeGroup] = useState([]);
  const { openNotification } = useNotification();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const students = attendees.filter(attendee => attendee.role === Roles.Student);
  const isAdvisorJoined = attendees.find(attendee => attendee.role === Roles.Advisor);
  const studentsPrevious = usePrevious(students) || [];
  const { service: whiteboardService, addPage } = useWhiteboard();
  const { networkNotice, closeNetworkNotice, openNetworkNotice } = useNetworkContext();
  const { userId: studentInfoId, studentInfoState, toggleStudentInfo } = useStudentInfoContext();
  const { receiveRaiseHandAction, receiveLowerHandAction, receiveAppFinishInit } = useRealtimeActions();
  const { insertPictureModalSwitch } = useRoom();
  const [classroomPerformanceModalState, setClassroomPerformanceModalState] = useState(false);
  const mainRef = useRef(null);

  useViewMode();
  useIframeBridge();
  useInteractiveObjects();
  const breakoutRoomStorage = window.localStorage;
  const callTeacherGroupsPrevious = usePrevious(callTeacherGroups);

  const closeTeachingMaterialHandler = material => {
    switch (material.type) {
      case 'iframe':
        setIframeGroup(prev => prev.filter(item => item.sources !== material.sources));
        break;
      default:
        break;
    }
  };

  const toggleUsersModalHandler = () => {
    toggleUsersModal();
    usersModalState ? videoListCarouselStart() : videoListCarouselStop();
  };

  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const [muteDefault, setMuteDefault] = useState(true);
  const muteDefaultHandler = (bool) => setMuteDefault(bool);
  const isJoined = meetingState.matches(MeetingStates.Joined);

  useEffect(() => {
    if (!isVideoEnabled && query.role !== Roles.Advisor) {
      toggleVideo();
    }
  }, [isJoined]);

  const notificationStudentNumberChangeHandler = () => {
    const complementarySetResult = complementarySet(students, studentsPrevious);
    if (students.length > studentsPrevious.length) {
      complementarySetResult.map(student => openNotification({
        name: student.name,
        type: NOTIFICATION_TYPE.ENTER,
        key: NOTIFICATION_TYPE_KEY.ENTER
      }));
    } else {
      complementarySetResult.map(student => openNotification({
        name: student.name,
        type: NOTIFICATION_TYPE.LEAVE,
        key: NOTIFICATION_TYPE_KEY.LEAVE
      }));
    }
  };

  const [isShowPreviewMenu, setIsShowPreviewMenu] = useState(false);

  const pagePreviewHandler = (state) => {
    setIsShowPreviewMenu(state);
  };

  const cancelBreakoutRoomEndNoticeHandler = () => closeBreakoutRoomEndNotice();

  const closeBreakoutRoomEndNoticeHandler = async () => {
    closeBreakoutRoomEndNotice();
    toggleBreakoutRoomIsLeaving();
    breakoutMeetingLeave();
    await stopBreakout(meetingId);

    removeAllCallTeacherGroups();
    breakoutRoomStorage.removeItem('breakoutRoomData');
    breakoutRoomStorage.removeItem('callTeacherData');
    setBreakoutRoomIsEndState(true);
    resultReviewActions.setTrue();
  };

  const [groupInvitationModal, setGroupInvitationModal] = useState(false);
  const toggleGroupInvitationModal = () => setGroupInvitationModal(prev => !prev);

  const getResultData = async () => {
    const data = await getBreakoutLatest(meetingId);
    if (!data) return;
    setBreakoutRoomResultData(data);
    breakoutRoomResultActions.setTrue();
    resultReviewActions.setTrue();
  };

  useEffect(() => {
    if (breakoutRoomIsEndState) {
      getResultData();
    }
  }, [breakoutRoomIsEndState]);

  useEffect(() => {
    notificationStudentNumberChangeHandler();
  }, [students.length]);

  useEffect(() => {
    if (!audioVideo) {
      return;
    }
    const rosterUpdateCallback = (
      chimeAttendeeId,
      present,
    ) => {
      if (!present) {
        removeUsersMute(chimeAttendeeId);
        const isOnStaged = stagedAttendeeIds.includes(chimeAttendeeId);
        if (isOnStaged) unstageAttendee({ attendeeId: chimeAttendeeId });

        return;
      }
      if (isBreakoutRoom) return;
      muteDefault ? muteAttendee({ attendeeId: chimeAttendeeId }) : unMuteAttendee({ attendeeId: chimeAttendeeId });
      updateStaged({ attendeeId: chimeAttendeeId, stagedAttendeeIds });
      usersMuteHandler({
        [chimeAttendeeId]: muteDefault
      });

      const isEnableToolbox = toolboxAuthAttendeeIds.includes(chimeAttendeeId);
      if (isEnableToolbox) {
        enableToolbox({ attendeeId: chimeAttendeeId });
      }
    };

    audioVideo.realtimeSubscribeToAttendeeIdPresence(rosterUpdateCallback);

    return () => {
      audioVideo.realtimeUnsubscribeToAttendeeIdPresence(rosterUpdateCallback);
    };
  }, [audioVideo, muteDefault, stagedAttendeeIds, toolboxAuthAttendeeIds]);

  const [breakoutRoomInfo, setBreakoutRoomInfo] = useState(null);
  const [breakoutRoomGrouping, setBreakoutRoomGrouping] = useState(false);

  const getLatestData = async () => {
    const data = await getBreakoutLatest(meetingId);
    if (!data) return;
    setBreakoutRoomResultData(data);
    const groupInfo = data.groups.filter(group => group.roomId === breakoutId);
    setBreakoutRoomInfo(groupInfo[0]);
    setBreakoutRoomGrouping(data.status === 'Start' ? true : false);
  };

  useEffect(() => {
    if (!breakoutRoomGrouping) return;
    changeBreakoutRoomStep(breakoutRoomGrouping ? BreakoutRoomState.grouping : BreakoutRoomState.setting);
  }, [breakoutRoomGrouping]);

  useEffect(() => {
    getLatestData();
  }, []);

  useEffect(() => {
    if (!callTeacherGroupsPrevious) return;
    if (callTeacherGroupsPrevious.length === 0) setGroupInvitationModal(true);
  }, [callTeacherGroups]);

  const goBreakoutInviteHandler = () => {
    goToBreakoutMeeting({ breakoutId: callTeacherGroups[0].breakoutRoomId });
  };

  const handleSceneUpload = () => {
    pagePreviewHandler(true);
    setTimeout(() => {
      const element = document.querySelector('nav.bm-item-list > .bm-item');
      element.scrollTop = element.scrollHeight;
    }, 100);
  };

  const { sharingAttendeeId } = useContentShareState();
  const contentSharingAttendeeId = useMemo(() => sharingAttendeeId?.split('#')[0] || '', [sharingAttendeeId]);
  const isShowContentShare = useMemo(() =>
    contentSharingAttendeeId && contentSharingAttendeeId !== selfAttendeeId,
    [selfAttendeeId, contentSharingAttendeeId]);

  const isSelfContentShare = useMemo(() =>
    contentSharingAttendeeId && contentSharingAttendeeId === selfAttendeeId,
    [selfAttendeeId, contentSharingAttendeeId]);

  const [broadcastValue, setBroadcastValue] = useState('');

  const broadcastValueChangeHandler = (e) => {
    const textAreaValue = e.target.value.trim();
    if (textAreaValue.length > 280) return;

    setBroadcastValue(textAreaValue);
  };

  const broadcastModalCloseHandler = () => {
    setBroadcastModalActions.setFalse();
  };

  const { loading: broadcastModalLoading, run: broadcastMessageRun } = useRequest(() => broadcastMessageToAll({ courseId: meetingId, message: broadcastValue }), {
    manual: true
  });

  const broadcastModalSubmitHandler = async () => {
    await broadcastMessageRun();
    setBroadcastValue('');
    setBroadcastModalActions.setFalse();
  };

  const { teacherQuizStateMatches, States } = useQuizService();
  const isOpenQuizModal = [States.Setting, States.Starting, States.End, States.Reviewing].some(
    teacherQuizStateMatches
  );

  const [stagedData, setStagedData] = useSessionStorageState(
    'stagedData',
    {
      defaultValue: []
    }
  );

  useEffect(() => {
    setStagedData(stagedAttendeeIds);
  }, [stagedAttendeeIds]);

  useEffect(() => {
    if (stagedData.length !== 0) {
      updateStaged({
        attendeeId: selfAttendeeId,
        stagedAttendeeIds: stagedData
      });
    }
  }, []);

  const isTilePaused = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Teacher}.${MeetingStates.TileState}.${MeetingStates.Paused}`
  });

  useEffect(() => {
    if (isTilePaused) {
      openNetworkNotice();
    }
  }, [isTilePaused]);

  useEffect(() => {
    if (!audioVideo) return;

    receiveRaiseHandAction();
    receiveLowerHandAction();
    receiveAppFinishInit();
  }, [audioVideo, receiveRaiseHandAction, receiveLowerHandAction, receiveAppFinishInit]);

  const openClassroomPerformanceModal = () => setClassroomPerformanceModalState(true);

  const closeClassroomPerformanceModal = () => setClassroomPerformanceModalState(false);

  /**
   * 老師自己的輪播列表
   */
  const nextLocalVideoList = useMemo(() => {
    return localVideoList.filter((_tile, index) => index < MAX_STUDENT_VIDEO_COUNT);
  }, [localVideoList]);

  /**
   * 顧問在的時候在 broadcast 列表資訊
   */
  useEffect(() => {
    if (!nextLocalVideoList || !isAdvisorJoined) return;
    updateVideoList({ videoList: nextLocalVideoList });
  }, [isAdvisorJoined, nextLocalVideoList]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%">
      {!isBreakoutRoom && breakoutRoomStep === BreakoutRoomState.grouping && <NotificationLayout>
        <BreakoutRoomNotification meetingId={meetingId} />
      </NotificationLayout>}
      {isBreakoutRoom && <NotificationLayout>
        <TeacherSubRoomNotification title={breakoutRoomInfo?.groupName} />
      </NotificationLayout>}
      {breakoutRoomStep === BreakoutRoomState.setting && resultReviewState && whiteboardService && <NotificationLayout>
        <BreakoutRoomResultNotification service={whiteboardService} />
      </NotificationLayout>}
      {networkNotice && <NotificationLayout>
        <NetworkNotification onClose={closeNetworkNotice} />
      </NotificationLayout>}

      <StyledSyncMultiple isNotification={breakoutRoomStep !== BreakoutRoomState.setting || resultReviewState || networkNotice}>
        <MainLayout
          right={
            <RightContainer>
              <MainPageHeader />
            </RightContainer>
          }
          mode='sync'
          className={`${query.role} ${isBreakoutRoom ? 'isBreakoutRoom' : ''}`}
        >
          <SyncMainBlock ref={mainRef}>
            <PrivateMessageList filterRole={Roles.Teacher} containerRef={mainRef} />

            {nextLocalVideoList.length > 0 && <StyledSyncVideoList>
              <SyncVideoList videoList={nextLocalVideoList} />
            </StyledSyncVideoList>}

            {(!isBreakoutRoom && whiteboardService && !isSelfContentShare) && <SceneTab room={whiteboardService} />}
            <SyncWhiteboard toolBox={<SyncToolBox meetingId={meetingId} />}>

              <Box
                width="56px"
                position="absolute"
                zIndex={50}
                bottom="96px"
                right={2}
                mb={2}
              >
                <Box mb={2}>
                  <ClassroomPerformanceButton onOpen={openClassroomPerformanceModal} />
                </Box>
              </Box>
              <Box
                position="absolute"
                zIndex={50}
                bottom="40px"
                right={2}
              >
                <RecordButton
                  mode='light'
                  placement="leftBottom"
                  content={t('mainPage.teacher.syncMultiple.remindToTurnCameraOn', '開始上課了嗎?\n別忘了按下錄影按鈕，讓學生可以在課後複習本次課程哦！')}
                />
              </Box>
              <Box
                display="flex"
                position="absolute"
                zIndex={5}
                bottom={2}
                right={2}
              >
                {!isBreakoutRoom && <Box mr={2}>
                  <RaiseHandControl sync />
                </Box>}
                {!isBreakoutRoom && <Box mr={2}>
                  <IconBox>
                    <MuteAll muteDefault={muteDefault} muteDefaultControl={muteDefaultHandler} />
                  </IconBox>
                </Box>}
                <Box mr={2} onClick={() => breakoutRoomIntroActions.toggle()}>
                  <IconBox>
                    <Icon name="ClassSolid" size="sm" />
                  </IconBox>
                </Box>
                {!isBreakoutRoom && <>
                  <Box mr={2}>
                    <IconBox onClick={toggleUsersModalHandler}>
                      <Icon name="UsersSolid" />
                    </IconBox>
                  </Box>
                  <Box mr={2}>
                    <IconBox>
                      <OtherButton />
                    </IconBox>
                  </Box>
                  <Box mr={2}>
                    <IconBox onClick={() => pagePreviewHandler(true)}>
                      <Icon name="ThSolid" size="xs" />
                    </IconBox>
                  </Box>
                  <Box mr={2}>
                    <IconBox>
                      <SettingPopoverButton
                        className="settingPopoverButton"
                        placement="leftTop"
                        disabledPopover
                      />
                    </IconBox>
                  </Box>
                  <Box mr={2}>
                    <ScenesImageUploadButton service={whiteboardService} onUploaded={handleSceneUpload}>
                      <IconBox>
                        <Icon name="ImageSolid" size="xs" />
                      </IconBox>
                    </ScenesImageUploadButton>
                  </Box>
                  <Box>
                    <IconBox onClick={addPage}>
                      <Icon name="FileAddSolid" size="xs" />
                    </IconBox>
                  </Box>
                  <Box ml={2}>
                    <PageController room={whiteboardService} />
                  </Box>
                </>}
              </Box>

              <PreviewController
                room={whiteboardService}
                isVisible={isShowPreviewMenu}
                handlePreviewState={pagePreviewHandler}
              />

              {classroomPerformanceModalState && <ClassroomPerformanceModal students={students} onClose={closeClassroomPerformanceModal} />}
            </ SyncWhiteboard>

            {isSelfContentShare && (
              <Box width="100%" height="100%" position='absolute' zIndex='1000'>
                <ContentShare />
                <Box position='absolute' right='8px' bottom='8px'>
                  <IconBox>
                    <SettingPopoverButton
                      className="settingPopoverButton"
                      placement="leftTop"
                      disabledPopover
                    />
                  </IconBox>
                </Box>
              </Box>
            )}

            {
              isShowContentShare && (
                <ContentShareContainer>
                  <ContentShare />
                </ContentShareContainer>
              )
            }

            <TeachingMaterialContainer iframeGroup={iframeGroup} onClose={closeTeachingMaterialHandler} />

            {worldWallModalState && <WorldWallModal onClose={toggleWorldWallModal} />}

            {carouselPanelState &&
              <VideoListCarouselPanel
                onClose={toggleCarouselPanel} />}
            {onstageFullModalState && <Modal onClose={toggleStageFullModal} header={t('mainPage.teacher.syncMultiple.warningNotice', '警告通知')} >
              {t('mainPage.teacher.syncMultiple.stageFulled', '人數已滿無法再邀請人上台')}</ Modal>}
            <StyleUsersModalLayout active={usersModalState}>
              <UsersModalMap.UsersModal onClose={toggleUsersModalHandler} />
            </StyleUsersModalLayout>

            {breakoutRoomStep === BreakoutRoomState.setting && breakoutRoomResultState && <BreakoutRoomResult service={whiteboardService} />}
          </SyncMainBlock>

        </MainLayout>
        <Sidebar isSidebar={breakoutRoomIntroState}>
          <BreakoutRoomIntro onClose={breakoutRoomIntroActions.setFalse} />
        </Sidebar>
        <Sidebar isSidebar={breakoutRoomSidebarState}>
          <BreakoutRoomContainer onClose={toggleBreakoutRoomSidebar} isGrouping={breakoutRoomGrouping} />
        </Sidebar>

        {breakoutRoomEndNotice && <Modal onClose={cancelBreakoutRoomEndNoticeHandler} header={t('mainPage.teacher.syncMultiple.endGroup', '結束分組')}>
          <Box>
            {t('mainPage.teacher.syncMultiple.endGroupDiscussionConfirmation', '結束分組討論後，分組成員將有30秒緩衝時間，將會在緩衝時間後回到主教室，結束分組後將會進入分組檢討，是否確認結束此次分組討論？')}
          </Box>
          <Box display="flex" width="100%" pt={8}>
            <Box width="100%" mr={2}>
              <FullButton.Secondly onClick={cancelBreakoutRoomEndNoticeHandler}>{t('mainPage.teacher.syncMultiple.cancel', '取消')}</FullButton.Secondly>
            </Box>
            <Box width="100%" ml={2}>
              <FullButton onClick={closeBreakoutRoomEndNoticeHandler}>{t('mainPage.teacher.syncMultiple.endGroupDiscussion', '結束分組討論')}</FullButton>
            </Box>
          </Box>
        </Modal>}
        {groupInvitationModal && callTeacherGroups.length === 1 && <Modal width="600px" onClose={toggleGroupInvitationModal} header={t('mainPage.teacher.syncMultiple.inviteToJoinDiscussion', '收到加入討論邀請')}>
          <Box>
            {t('mainPage.teacher.syncMultiple.joinDiscussionConfirmation', '已收到 {{groupName}} 分組討論室的加入邀請，是否立即加入討論？', { 'groupName': callTeacherGroups[0].groupName })}
          </Box>
          <Box display="flex" width="100%" pt={8}>
            <Box width="100%" mr={2}>
              <FullButton.Secondly onClick={toggleGroupInvitationModal}>{t('mainPage.teacher.syncMultiple.joinLater', '稍後加入')}</FullButton.Secondly>
            </Box>
            <Box width="100%" ml={2}>
              <FullButton onClick={goBreakoutInviteHandler}>{t('mainPage.teacher.syncMultiple.joinNow', '立即加入')}</FullButton>
            </Box>
          </Box>
        </Modal>}

        {broadcastModalState && <Modal width="600px" header={t('mainPage.teacher.syncMultiple.broadcastMessage', '廣播訊息')}>
          <StyledBroadcastModal>
            <div className="content">
              <textarea placeholder={t('mainPage.teacher.syncMultiple.enterBroadcastMessage', '在這裡輸入廣播訊息，傳送給所有成員。')} maxLength={280} onChange={broadcastValueChangeHandler}></textarea>
              <div className="tip">{broadcastValue.length}/280</div>
            </div>
          </StyledBroadcastModal>
          <Box display="flex" width="100%" pt={8}>
            <Box width="100%" mr={2}>
              <FullButton.Secondly onClick={broadcastModalCloseHandler} disabled={broadcastModalLoading ? true : false}>{t('mainPage.teacher.syncMultiple.cancel', '取消')}</FullButton.Secondly>
            </Box>
            <Box width="100%" ml={2}>
              <FullButton onClick={broadcastModalSubmitHandler} disabled={broadcastValue.length === 0 ? true : false}>
                {broadcastModalLoading ? <LoadingOutlined /> :
                  t('mainPage.teacher.syncMultiple.sendMessage', '發送訊息')}
              </FullButton>
            </Box>
          </Box>
        </Modal>}

        {insertPictureModalSwitch && <InsertPictureModal />}

        {
          isOpenQuizModal && <QuizModal role={Roles.Teacher} />
        }

        {(whiteboardService && studentInfoState) && <StudentInfoBox id={studentInfoId} onClose={toggleStudentInfo} room={whiteboardService} />}
      </StyledSyncMultiple>
    </Box>

  );
};
