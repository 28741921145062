import { useEffect } from 'react';
import { useWhiteboard } from '@oneboard/whiteboard';
import { IframeBridge } from '@oneboard/iframe-bridge';
import { useInteractiveLearning } from 'providers/InteractiveLearningProvider';
import { getInteractiveLearningById } from 'api/oneClub';

export const useIframeBridge = () => {
  const { service: whiteboardService } = useWhiteboard();
  const { interactiveLearningMap, setInteractiveLearningMap } = useInteractiveLearning();

  useEffect(() => {
    if (!whiteboardService) return;



    async function handler(state) {
      if (state.sceneState?.scenePath.indexOf('book_iframe_') > -1) {
        const bookId = state.sceneState.contextPath.split('book_iframe_')[1];
        const url = `${process.env.REACT_APP_ONEBOARD_READER_DOMAIN}/${bookId}/${state.sceneState.index}`;
        let bridge = whiteboardService.getInvisiblePlugin(IframeBridge.kind);
        if (bridge) {
          bridge.setAttributes({
            url,
            displaySceneDir: state.sceneState.contextPath,
          });
        }
      }

      if (state.sceneState?.scenePath.indexOf('interactive_learnings_') > -1) {

        const bookId = state.sceneState.contextPath.split('interactive_learnings_')[1];
        let gameCode = interactiveLearningMap?.[bookId]?.gameCode;
        if (!gameCode) {
          const { data } = await getInteractiveLearningById(bookId);
          gameCode = data?.interactiveLearning?.gameCode;
          setInteractiveLearningMap(prev => {
            return {
              ...prev,
              [bookId]: { gameCode }
            };
          });
        }

        const url = `${process.env.REACT_APP_INTERACTIVE_LEARNING_DOMAIN}?gameCode=${gameCode}`;
        let bridge = whiteboardService.getInvisiblePlugin(IframeBridge.kind);
        if (bridge) {
          bridge.setAttributes({
            url,
            displaySceneDir: state.sceneState.contextPath,
          });
        }
      }
    }

    whiteboardService.callbacks.on('onRoomStateChanged', handler);

    return () => whiteboardService.callbacks.off('onRoomStateChanged', handler);
  }, [whiteboardService, interactiveLearningMap]);

};